import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import useAPI from "../../hooks/useAPI";
import Swal from "sweetalert2";
import { useEffect } from "react";

const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

// Componente funcional para mostrar el toast de recarga
const ReloadToast = ({ closeToast, setLoading, dark, listOptions }) => {
    const {register, handleSubmit, formState: { errors }} = useForm()
    const now = new Date();
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const date = `${lastDay.getDate()} de ${monthNames[lastDay.getMonth()]} de ${lastDay.getFullYear()}`;
    
    const { urlAPI_1, urlAPI_2, urlAPI_3, urlSocket, fetchWithAuth } = useAPI();

    // console.log('----listOptions----> ', listOptions);

    const PaymentMethod = ({qr, link}) => {       
        return (
            <div className="grid grid-cols-[1fr_1px_80px] w-full">
                <div className="flex flex-col p-4">
                    <h3 className="text-zinc-800 text-sm font-semibold">Selecciona la opción para realizar el pago</h3>
                    <div className="d-flex justify-content-between mt-4 pt-1">
                        
                        <div className="text-center w-50">
                            <div>
                                <label>Escanear código QR</label>
                            </div>
                            <div>
                                <img src={qr} alt="QR" style={{width: "65%"}}/>
                            </div>
                        </div>
                        <div className="text-center w-50">
                            <div>
                                <label>Ir a la pasarela de pagos</label>
                            </div>
                            <div id="content-img-pay">
                                <a href={link} target="_blank" rel="noreferrer" onClick={() => toast.dismiss()}>
                                <img className="img-pay" src="/images/Icono-pagos.png" alt="QR" style={{width: "65%"}}/>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="d-flex justify-content-evenly mt-1 pt-1">
                    <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => toast.dismiss()}>Cancelar</a>
                </div>
            </div>
        );
    }

    const postReload = async (data) => {
        console.log('data........', data);       
        const optionSelected = listOptions.find((option) => option.value == data.options);
    
        //#--> Enviar información para generar link de pago
        console.log('optionSelected::', optionSelected);
        
        setLoading(true);
        try {
            const url = urlAPI_1 + "payBill";
            const options = { method: "POST", mode: "cors", body: JSON.stringify(optionSelected)};
            const response = await fetchWithAuth(url, options);
            const result = await response.json();
    
            console.log("Response of the API: Post payBill-------=>", result);
    
            if (result.status === "error") {
                Swal.fire({
                    title: "¡Atención!",
                    text: result.message,
                    icon: result.status,
                });
                setLoading(false);
            } else {
                // setPaymentOptions(true);
                const qr = result.data.data.routeQr;
                const link = result.data.data.routeLink;
                toast(<PaymentMethod qr={qr} link={link} />, {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    theme: JSON.parse(dark) ? "dark" : "light",
                    className: "custom-toast-container",
                    bodyClassName: "custom-toast-body",
                });
                closeToast();
                setLoading(false);
            }
    
        } catch (error) {
            console.log(error);
        }
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
        }).format(value);
    };
    
    return (
        <>
            <div className="grid grid-cols-[1fr_1px_80px] w-full">
                <form onSubmit={handleSubmit(postReload)}>
                    <div className="flex flex-col p-4">
                        <h3 className="text-zinc-800 text-sm font-semibold">Selecciona la opción de tu interés</h3>
                        <div className="d-flex justify-content-between mt-4 pt-1">
                            {/* {optionsList.map((option, index) => ( */}
                            {listOptions.map((option, index) => (
                                <label key={index} id="reload-option" htmlFor={option.value} className="pointer p-2 mx-2">
                                    <div className="d-flex justify-content-between radio mt-0">
                                        <input
                                            className="pointer"
                                            style={{ accentColor: '#5BC0DE', width: "1rem" }}
                                            type="radio"
                                            id={option.value}
                                            value={option.value}
                                            {...register("options", {
                                                required: {
                                                    value: true,
                                                    message: "Debes seleccionar una opción.",
                                                }
                                            })}
                                            onChange={(e) => { console.log('-------radio--------', e.target.value) }}
                                        />
                                        <label htmlFor={option.value} className="d-flex align-items-end radio-label"></label>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <label htmlFor={option.value} className="d-flex mb-0 ml-1" style={{ padding: "5px 2px 5px" }}>{option.title}</label>
                                            <label htmlFor={option.value} className="d-flex mb-0 ml-1" style={{ padding: "5px 2px 5px" }}>{formatCurrency(option.amount)}</label>
                                        </div>
                                    </div>
                                    <div className="text-jusify" style={{ fontSize: "0.9rem" }}>{option.body}</div>
                                </label>
                            ))}
                        </div>
                        {errors.options && <Alert className="w-100 mt-3 ml-2">* {errors.options.message}</Alert>}
                        <div className="mt-3">
                            <label style={{fontSize: "0.8rem", fontStyle: "italic", fontWeight: "100"}}>
                                <i className="icon-info-circled" style={{ color: "#46a3d6" }}></i> La opción adicional es válida hasta el final del período de reinicio ({date}).
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-evenly mt-1 pt-1">
                        <a
                            href="javascript:;"
                            className="btn btn-lg btn-default mr-1"
                            onClick={() => {
                                console.log('cancelarrrrr........')
                                closeToast();
                            }}
                        >
                            Cancelar
                        </a>
                        <input
                            className="btn btn-lg btn-info"
                            value="Continuar"
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </>
    );
};

// Componente funcional para mostrar el toast de límite de conversaciones
const SplitButtons = ({ message, closeToast, setLoading, dark }) => {    

    const { urlAPI_1, urlAPI_2, urlAPI_3, urlSocket, fetchWithAuth } = useAPI();

    useEffect(() => {
        getPackages()
    },[])

    const getPackages = async () => {
        try {
            const url = urlAPI_2 + "packages";
            const options = { method: "GET", mode: "cors"};
            const response = await fetchWithAuth(url, options);
            const result = await response.json();
            console.log("Response of the API: obtener paquetes adicionales::::....>", result);
            return result.data
        } catch (error) {
            console.log(error);
        }
    }

    const handleReloadClick = async () => {
        closeToast();
        const data = await getPackages();
        console.log("Paquetes obtenidos:", data);

        toast(<ReloadToast setLoading={setLoading} dark={dark} listOptions={data} />, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            theme: JSON.parse(dark) ? "dark" : "light",
            className: "custom-toast-container",
            bodyClassName: "custom-toast-body",
        });
    };

    return (
        <div className="grid grid-cols-[1fr_1px_80px] w-full">
            <div className="flex flex-col p-4">
                <h3 className="text-zinc-800 text-sm font-semibold">¡UPS!</h3>
                <p className="text-sm">{message}</p>
            </div>
            <div className="bg-zinc-900/20 h-full" />
            <div className="d-flex justify-content-evenly mt-1 pt-1">
                <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => closeToast()}>Cancelar</a>
                <input
                    className="btn btn-lg btn-info"
                    value="Recargar"
                    type="button"
                    onClick={handleReloadClick}
                />
            </div>
        </div>
    );
};

// Función para mostrar el toast de límite de conversaciones
export const showToast = (message, setLoading, isDark) => {
    toast.warning(
        <SplitButtons message={message} setLoading={setLoading} dark={isDark} />, 
        {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            theme: JSON.parse(isDark) ? "dark" : "light",
            className: "custom-toast-container",
            bodyClassName: "custom-toast-body",
        }
    );
};

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;