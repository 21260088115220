import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import styled from "styled-components";
import Swal from "sweetalert2";
import Spinner from "../plugins/Spinner";
import { useForm } from "react-hook-form";
import Pagination from "../plugins/Pagination";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import { BsSearch, BsTrash3 } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { TbTemplate } from "react-icons/tb";
import { ToastContainer, toast } from 'react-toastify';

const Templates = () => {
  //# Estados del Modulo (Modales, Spinner, etc)
  const [loading, setLoading] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [validation, setValidation] = useState(false);
  const [isDark, setIsDark] = useState(false);

  //# Estados para los servicios
  const [templates, setTemplates] = useState([]);
  const [tableTemplates, setTableTemplates] = useState([]);

  const [search, setSearch] = useState("");

  const { page, setPage, byPage, resetPage } = usePage();
  const { urlAPI, urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  const navigate = useNavigate();

  //# React-hook-form para establecer validaciones
  const {watch, register, handleSubmit, formState: { errors }, reset,} = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      type: "",
      content: "",
      // category: "",name: "",lenguages: "",lenguagesTemplate: [],headerType: "",headerText: "",media: "",body: "",footer: "",buttonType: "",fastAnswerText: "",fastAnswerText2: "",actionType: "",actionType2: "",bottonTextAction: "",bottonTextActionGoToWebsite: "",bottonTextAction2: "",country: "",country2: "",phoneNumber: "",phoneNumber2: "",urlType: "",urlType2: "",websiteUrl: "",websiteUrl2: "",isBottons: false,isHeader: ""
    },
  });

  useEffect(() => {
    const dark = localStorage.getItem("IS_DARK");
    console.log('DARK: ', dark);
    console.log('DARK 2: ', localStorage.getItem("THEME"));
    
    setIsDark(dark)
    getTemplates();
  }, []);

  const sendToast = async (result) => {
    console.log('DARK en sendToast: ', isDark);
    console.log('DARK JSON.parse(isDark): ', JSON.parse(isDark));
    const obj = {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      // theme: "dark",
      theme: JSON.parse(isDark) ? "dark" : "light",
      className: "custom-toast-container",
      bodyClassName: "custom-toast-body",
    }

    // Mapa de tipos de toast
    const toastType = {
      success: toast.success,
      warning: toast.warning,
      info: toast.info,
      error: toast.error,
    };

    (toastType[result.type] || toast.error)(result.message, obj);
  }

  //# Listar plantillas
  const getTemplates = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "templates";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("LISTA PLANTILLAS ==========", result);
      setLoading(false);
      if (result.type === "error") {
        await sendToast(result)
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        const obj = {type: "info", message: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales'}
        await sendToast(obj)
        navigate('/');
      }  else if (!result.data) {
        setValidation(true);
        setTemplates([]);
      } else {
        setTemplates(result.data);
        setTableTemplates(result.data);
        setValidation(false);

      }
    } catch (error) {
      console.log(error);
    }
  };

  //# Sincronizar lista desde Facebook
  const getTemplatesFacebook = async () => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas sincronizar las plantillas?",
        text: "Se actualizara la lista.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aceptar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log('Confirmadooo....');
          setLoading(true);
          const url = urlAPI_2 + "syncTemplates";
          const options = { method: "GET", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          console.log("Resultado sincronización ==========>", result);
          setLoading(false);
          if (result.type === "error") {
            await sendToast(result)
          } else if (result.type === "warning") {
            Swal.fire({
              title: result.message,
              text: "¿Desea ir a configurar el campo?",
              icon: result.type,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sí',
              cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/#/home/myData";
              }
            })
          } else if (result.message === 'Tu petición no tiene cabecera de autorización') {
            const obj = {type: "info", message: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales'}
            await sendToast(obj)
            navigate('/');
          } else {
            await getTemplates();
            await sendToast(result)
            
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  };

  //# Servicio DELETE para listar
  const deleteTemplate = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar esta plantilla?",
        text: "No podrás recuperarla.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_2 + `template/${id}`;
          const options = { method: "DELETE", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          console.log("Data Eliminated", result);

          //# Actualización de la tabla
          setLoading(false);
          await getTemplates();
          await sendToast(result)
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const cleanInputs = () => {
    reset({ name: "", type: "", content: "" });
  };

  const searchQuery = (searchTerm) => {
    var searchResults = tableTemplates.filter((element) => {
    const filter = element.content.filter(tem => {if (tem.type === "BODY") return tem.text})
    const newContent = filter[0].text;
    
    if (
      element.name
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.language
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.category
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      newContent
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.status
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    ) {
      return element;
    }
      
    });
    setTemplates(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const formatTemplates = [...templates].reverse();


  function parseBoolean(value) {
    if (typeof value === 'boolean') {
      return value;
    } else if (typeof value === 'string') {
      const lowerCaseValue = value.toLowerCase();
      if (lowerCaseValue === 'true') {
        return true;
      } else if (lowerCaseValue === 'false') {
        return false;
      }
    }
    // Si no es ni booleano ni una cadena válida, devuelve false por defecto
    return false;
  }

  const handleTemplateIsActive = async (e, template) => {
    try {
      setLoading(true);
      const url = urlAPI_2 + `syncTemplates/${template.id}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify({isActive:e.target.checked})};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setLoading(false);      
      await sendToast(result)
    } catch (error) {
      console.log('Error al actualizar active de template:', error);
    }
  }

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div className="mt-2">
            <h1>Plantillas</h1>
            <input
              type="button"
              className="btn btn-default"
              value="Agregar nueva"
              onClick={() => navigate('/home/templateWhatsapp') }
            />
            <input
              type="button"
              className="btn btn-default ml-1"
              value="Sincronizar"
              onClick={() => getTemplatesFacebook()}
            />
          </div>

          <Header />
        </div>

        <header>
          <div style={{ float: "right" }}>
            <form className="form-inline">
              <div className="su-control my-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchQuery(e.target.value);
                    resetPage();
                  }}
                />
                <button
                  className="btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </form>
          </div>
        </header>

        <div className="container-fluid mt-3 px-0" style={{ height: "75%", overflowY: "auto", overflowX: "none" }}>
          <table className="table table-striped">
            <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
              <tr>
                <th>Acciones</th>
                <th>Activar/Desactivar</th>
                <th>Nombre</th>
                <th>Contenido</th>
                <th>Idioma</th>
                <th>Categoría</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {formatTemplates.length > 0 && formatTemplates.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((template, k) => { 
                const filter = template.content.filter(tem => {
                  if (tem.type === "BODY") return tem.text
                })
                const newContent = filter;
                
                return(
                <tr key={k}>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="dropdown">
                        <button
                          // onClick={() => getTemplate(template._id)}
                          className="bg-transparent border-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <FaEllipsisVertical className="text-success f-24 pointer"/>
                        </button>
                        <div
                          className="dropdown-menu p-2"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button
                            className="text-primary border-0 bg-transparent d-block pointer mt-2"
                            onClick={() => {
                              navigate(`/home/templateWhatsappEdit/${template._id}`);
                              setModeEdit(true);
                            }}
                          >
                            {/* <i
                              className="icon-pencil"
                              style={{ fontSize: "18px" }}
                            />
                            <span className="text-dark ml-2 f-14">
                              Editar
                            </span> */}
                            <FaEye style={{fontSize: "18px",color: "#ff9317",}} />
                            <span className="text-dark ml-2 f-14">
                              Ver
                            </span>
                          </button>
                          <button className="text-danger border-0 bg-transparent d-block pointer mt-2" onClick={() => deleteTemplate(template.id)}>
                            <BsTrash3 style={{ fontSize: "18px" }}/>
                            <span className="text-dark ml-2 f-14">
                              Eliminar
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center align-items-center">
                      <input
                        type="checkbox"
                        value={parseBoolean(template.isActive)}
                        className="cmn-toggle cmn-toggle-round-flat"
                        name={template.id}
                        id={template.id}
                        defaultChecked={parseBoolean(template.isActive)}
                        onChange={(e) => {
                          handleTemplateIsActive(e, template)
                        }}
                      />
                      <label className="m-0" htmlFor={template.id}></label>
                    </div>
                  </td>
                  <td>
                    {template.name.length >= 35
                      ? template.name.substr(0, 35) + "..."
                      : template.name}
                  </td>
                  <td>{
                    newContent[0].text.length >= 35 ? 
                      newContent[0].text.substr(0, 35) + "..."
                      : newContent[0].text}
                  </td>
                  <td>{template.language}</td>
                  <td>{template.category}</td>
                  <td className="status-template">
                    <p>
                      <a
                        href="javascript:;"
                        className={
                          template.status === "PENDIENTE"
                            ? "gray"
                            : template.status === "APROBADA"
                            ? "green"
                            : "red"
                        }
                      >
                        {template.status}
                      </a>
                    </p>
                  </td>
                </tr>
              )})}
            </tbody>
          </table>
          {validation && (
            <div className="text-center mt-3">
              <div className="empty">
                <TbTemplate style={{ fontSize: "55px" }} />
                <h1>No tienes plantillas</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Sincronizar"
                  onClick={() => getTemplatesFacebook()}
                />
              </div>
            </div>
          )}
        </div>

        <footer>
          <div className="row">
            <div className="col-md-8"></div>

            <div className="col-md-4">
              {/* Creación de la paginación simple */}
              <Pagination
                totalData={formatTemplates.length}
                byPage={byPage}
                setPage={setPage}
              />
            </div>
          </div>
        </footer>

        <ToastContainer 
          position="top-center"
          autoClose={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          // theme={"light"}
          theme={JSON.parse(isDark) ? "dark" : "light"}
          toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
          bodyClassName="custom-toast-body"
        />
      </div>
    </Fragment>
  );
};

export default Templates;
