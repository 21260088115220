import React, { Fragment, useState, useEffect } from "react";
import Header from "./Header";
import logoLogin from "../../imgs/logo-login.png";
import { Line } from 'react-chartjs-2';
import RealTimeMetrics from "./metrics/RealTimeMetrics";
import HistoricalMetrics from "./metrics/HistoricalMetrics";
import MetricsPerAgent from "./metrics/MetricsPerAgent";
import useAPI from "../../hooks/useAPI";
import Swal from "sweetalert2";
import Spinner from "../plugins/Spinner";

import { ToastContainer, toast } from 'react-toastify';

const Dashboard = () => {

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('0');
  const [currentContent, setCurrentContent] = useState(1);
  const [historicalMetrics, setHistoricalMetrics] = useState({open: 0, closed: 0, onHold: 0, atentionTime: "-:-", waitTime: "-:-", area_data: {labels: [], data: []}});
  const [realTimeMetrics, setRealTimeMetrics] = useState({open: 0, closed: 0, onHold: 0, atentionTime: "-:-", waitTime: "-:-", lastHours: {}});
  const [dataByUsersMetrics, setDataByUsersMetrics] = useState({});
  const [dataAgents, setDataAgents] = useState([])
  const [isAdmin, setIsAdmin] = useState(false);
  const [socketConn, setSocketConn] = useState("");
  const [theme, setTheme] = useState("");

  const { urlAPI_1, urlAPI_2, urlSocket, fetchWithAuth } = useAPI();
  const token = localStorage.getItem("USER_TOKEN");

  const code_msg_admin = "0000000000";
  const code_msg_user = "1111111111";
  const code_msg_dashboard = "2222222222";
  const code_msg_system = "3333333333";

  useEffect(() => {
    const roleDescription = localStorage.getItem("USER_ROLEDESCRIPTION");
    const dark = localStorage.getItem("IS_DARK");
    setTheme(JSON.parse(dark)  ? "dark" : "light");
    console.log('Nombre del rol:::::::', roleDescription)
    if (roleDescription === "Administrador" || roleDescription === "SuperAdmin" || roleDescription === "Supervisor") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    getHistoricalMetrics('0');
    getRealTimeMetrics();
    getAgents();
    connectI(code_msg_dashboard);
  },[])

  //#--> Funcion para obtener la lista de agentes
  const getAgents = async () => {
    try {
        const url = urlAPI_2 + "usersbyArea";
        const options = { method: "GET", mode: "cors"};
        const response = await fetchWithAuth(url, options);
      
        const result = await response.json();

        if (result.code === 0) {
            Swal.fire({
              title: "¡Atención!",
              text: result.message,
              icon: result.type,
            });
        } else if (!result.data) {
            setDataAgents([]);
        } else {
            setDataAgents(result.data);
            localStorage.setItem("ID_COMPANY", result.idCompany);
        }
    } catch (error) {
        console.log(error);
    }
  };

  const getHistoricalMetrics = async (filter) => {
        setLoading(true);
        const url = urlAPI_2 + "getHistoricalMetrics/" + filter;
        const options = { method: "GET", mode: "cors"};
        const response = await fetchWithAuth(url, options);
      
        const result = await response.json();
        setLoading(false);
        console.log("getHistoricalMetrics: ", result.message);
        setHistoricalMetrics((prevMessage) => { return result.message.historical_data });
        setDataByUsersMetrics((prevMessage) => { return result.message.data_by_users });
  }

  const handleUpdateMetrics = (newFilter) => {
    setSelectedOption(newFilter);
    getHistoricalMetrics(newFilter)
  }

  const getRealTimeMetrics = async () => {
        const url = urlAPI_2 + "getRealTimeMetrics";
        const options = { method: "GET", mode: "cors"};
        const response = await fetchWithAuth(url, options);
      
        const result = await response.json();
        console.log("getRealTimeMetrics: ", result.message);
        setRealTimeMetrics((prevMessage) => { return result.message.real_time_data });
  }

  const connectI = (i) => {
    if (socketConn) {
      socketConn.close();
    }
    let socketConnAux = new WebSocket(urlSocket);
    socketConnAux.addEventListener("open", (e) => {
      const payload = {
        Action: "message", // route will match with this
        Msg: i,
      };
      try {
        socketConnAux.send(JSON.stringify(payload));
      } catch (error) {
        console.log("error ", error);
      }
    });

    socketConnAux.onmessage = async function (event) {
        const data_msg = JSON.parse(event.data);
        if (data_msg.message) {
            getRealTimeMetrics();
        }else{
            const msg_send = {};
            msg_send.connectionId = `${event.data}`;
            msg_send.phone = i;
            msg_send.agent = localStorage.getItem("USER_ID");
            msg_send.status = true;
            const url = urlAPI_2 + "saveConnection";
            const options = { method: "POST", mode: "cors", body: JSON.stringify(msg_send)};
            const response = await fetchWithAuth(url, options);
            const result = await response.json();
        }
    };

    socketConnAux.onclose = function (event) {
      console.log("Cerrando websocket. Estado del websocket: ", event.target.readyState);
    };

    setSocketConn(socketConnAux);
  };

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      <div className="col-xs-9 heading">
        <div>
          <h1>Dashboard</h1>
        </div>

        <Header />
      </div>
      <div id="content-all-metrics" className="container-fluid pt-3 px-0" style={{ height: "90%", overflowY: "auto", alignItems: "center", position: "relative" }}>

        {currentContent === 1 && (
          <RealTimeMetrics metrics={realTimeMetrics}/>
        )}
        
        {currentContent === 2 && (
          <HistoricalMetrics metrics={historicalMetrics} selectedOptionInit={selectedOption} onUpdateMetrics={handleUpdateMetrics}/>
        )}

        {isAdmin && currentContent === 3 && (
            <MetricsPerAgent dataAgents={dataByUsersMetrics} selectedOptionInit={selectedOption}  onUpdateMetrics={handleUpdateMetrics}/>
        )}
        
        <div className="content-buttons-metrics d-flex justify-content-center py-2">
          <button 
            className={currentContent === 1 ? "carousel-button mr-1 active" : "carousel-button mr-1"} 
            // style={{position: "absolute", bottom: "0", left: "48%"}} 
            onClick={() => setCurrentContent(1)}
          ></button>
          <button 
            className={currentContent === 2 ? "carousel-button mr-1 active" : "carousel-button mr-1"} 
            // style={{position: "absolute", bottom: "0", left: "50%"}} 
            onClick={() => setCurrentContent(2)}
          ></button>
          {isAdmin &&
            <button 
            className={currentContent === 3 ? "carousel-button active" : "carousel-button"} 
            // style={{position: "absolute", bottom: "0", left: "52%"}} 
            onClick={() => setCurrentContent(3)}
            ></button>
          }
        </div>

        
      <ToastContainer 
        position="top-center"
        autoClose={10000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={theme}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />

      </div>
    </Fragment>
  );
};

export default Dashboard;
