import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import Spinner from "../plugins/Spinner";
import Pagination from "../plugins/Pagination";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import { BsSearch, BsCheckCircle, BsExclamationCircle, BsXCircle, BsQuestionCircle } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { TbTemplate } from "react-icons/tb";
import { ToastContainer, toast } from 'react-toastify';

const LinesModule = () => {
  //# Estados del Modulo (Modales, Spinner, etc)
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [validation, setValidation] = useState(false);
  const [lines, setLines] = useState([]);
  const [linesTable, setLinesTable] = useState([]);
  const [isDark, setIsDark] = useState(false);

  //# Estados para los servicios
  const [search, setSearch] = useState("");

  const { page, setPage, byPage, resetPage } = usePage();
  const { urlAPI_2, fetchWithAuth } = useAPI();

  useEffect(() => {
    const dark = localStorage.getItem("IS_DARK");
    setIsDark(dark)
    getCompanie()
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function() {
        FB.init({
          appId: '900570192212204',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v19.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        js.async = true;
        js.defer = true;
        js.crossOrigin = "anonymous";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };

    loadFacebookSDK();
  }, []);

  const getCompanie = async () => {
    const resultIdCompany = localStorage.getItem("ID_COMPANY");
    try {
      setIsLoading(true);
      const url = urlAPI_2 + "companies/" + resultIdCompany;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API GETCOMPANY----------======>> ", result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setValidation(true);
      } else {
        setValidation(false);
        const {displayPhoneNumber, qualityRating, verifiedName, currentLimit, created, isDark} = result.data[0];
        setIsDark(isDark)
        const i = {
          name: verifiedName,
          line: displayPhoneNumber,
          // quality: "",
          quality: qualityRating,
          currentLimit: currentLimit,
          created: created
        }
        setLines([i])
        setLinesTable([i])
      }
      setIsLoading(false);
    } catch (error) {
      console.log('---------->',error);
    }
  };

  const launchWhatsAppSignup = () => {
    // Facebook Login with JavaScript SDK
    FB.login(
      (response) => {
        console.log("response de login de empresas: ", response);
        if (response.authResponse) {
          console.log("response.authResponse de login de empresas: ", response.authResponse);
          const code = response.authResponse.code;
          const getAccessToken = async () => {
            try {
              setIsLoading(true);
              setIsRegistering(true);
              const url = urlAPI_2 + "getAccessToken";
              const options = { method: "POST", mode: "cors", body: JSON.stringify({ code })};
              const response = await fetchWithAuth(url, options);
              const result = await response.json();
              setIsLoading(false);
              setIsRegistering(false);
              console.log(result);
              console.log("Access Token:", result.accessToken);
              if(result.result.error){
                // Swal.fire({
                //   title: "Error",
                //   text: "Se produjo un error durante el proceso de registro. Por favor contactese con el administrador.",
                //   icon: "error",
                // });
                toast.error("Se produjo un error durante el proceso de registro. Por favor contactese con el administrador.", {
                  position: "top-center",
                  autoClose: 7000,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  theme: JSON.parse(isDark) ? "dark" : "light",
                  className: "custom-toast-container", // Estilos personalizados para el toast
                  bodyClassName: "custom-toast-body",
                })
              }else{
                // Swal.fire({
                //   title: "Felicitaciones!",
                //   html: `El registro se ha realizado de forma exitosa. Puedes escribirle <a href="${result.result.wame}" target="_blank" style="text-decoration:underline;">aquí</a>`,
                //   icon: 'success',
                // });
                toast.success(`El registro se ha realizado de forma exitosa. Puedes escribirle <a href="${result.result.wame}" target="_blank" style="text-decoration:underline;">aquí</a>`, {
                  position: "top-center",
                  autoClose: 7000,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  theme: JSON.parse(isDark) ? "dark" : "light",
                  className: "custom-toast-container", // Estilos personalizados para el toast
                  bodyClassName: "custom-toast-body",
                })
                getCompanyData();
              }         
            } catch (error) {
              setIsLoading(false);
              setIsRegistering(false);
              console.error("Error al obtener el Access Token:", error);
            }
          };
          getAccessToken();
        } else {
          console.log("User cancelled login or did not fully authorize.");
          // Swal.fire({
          //   title: "Error",
          //   text: "El proceso de registro insertado fue cancelado.",
          //   icon: "error",
          // });
          toast.info("El proceso de registro insertado fue cancelado.", {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: JSON.parse(isDark) ? "dark" : "light",
            className: "custom-toast-container", // Estilos personalizados para el toast
            bodyClassName: "custom-toast-body",
          })
        }
      },
      {
        config_id: "609094528303718",
        response_type: "code",
        override_default_response_type: true,
        scope:
          "public_profile,email,business_management,whatsapp_business_management,whatsapp_business_messaging,catalog_management",
        return_scopes: true,
      }
    );
  };

  const getQualityRatingDisplay = (qualityRating) => {
    switch (qualityRating) {
      case "GREEN":
        return { text: "Alta", color: "#28a745", icon: <BsCheckCircle /> };
      case "YELLOW":
        return { text: "Media", color: "#ffc107", icon: <BsExclamationCircle /> };
      case "RED":
        return { text: "Baja", color: "#dc3545", icon: <BsXCircle /> };
      default:
        return { text: "Desconocida", color: "#6c757d", icon: <BsQuestionCircle /> };
    }
  };

  const getCurrentLimitDisplay = (currentLimit) => {
    switch (currentLimit) {
      case "TIER_50":
        return { text: "50"};
      case "TIER_250":
        return { text: "250"};
      case "TIER_1K":
        return { text: "1000"};
      case "TIER_10K":
        return { text: "10000"};
      case "TIER_100K":
        return { text: "100000"};
      case "TIER_UNLIMITED":
        return { text: "Ilimitado"};                        
      default:
        return { text: "Desconocido"};
    }
  };

  const searchQuery = (searchTerm) => {
    var searchResults = linesTable.filter((element) => {
    if (
      element.name
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.line
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) 
    ) {
      return element;
    }
      
    });
    console.log('lineas filtradas: ', searchResults);
    
    setLines(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const Modal = ({ line, closeToast }) => {
    return (
      <div className="grid grid-cols-[1fr_1px_80px] w-full">
        <div className="flex flex-col p-4" style={{width:"30em"}}>
          <div className="text-center">
            <h3 className="text-zinc-800 text-sm font-semibold">{line.name}</h3>
            <p className="text-zinc-800 text-sm font-semibold text-center">{line.line}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <label>Límite díario:</label>
            {(() => {
              const { text } = getCurrentLimitDisplay(line.currentLimit);
              return (
                <p className="text-sm">
                  {text}
                </p>
              );
            })()}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <label>Nivel de calidad:</label>
            {(() => {
              const { text, color, icon } = getQualityRatingDisplay(line.quality);
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: color,
                    color: "#fff",
                    borderRadius: "20px",
                    padding: "0px 10px",
                    fontWeight: "bold",
                    width: "auto",
                    height: "2rem"
                  }}
                >
                  <span style={{ marginRight: "10px", fontSize: "16px", marginTop: "4px" }}>{icon}</span>
                  {text}
                </div>
              );
            })()}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <label>Fecha de conexión:</label>
            <p className="text-sm">{dayjs(line.created).locale("es").format("DD MMMM YYYY")}</p>
          </div>
        </div>
        <div className="bg-zinc-900/20 h-full" />
        <div className="d-flex justify-content-evenly mt-1 pt-1">
          <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => closeToast()}>
            Cerrar
          </a>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader-content">
            {isRegistering && ( <p>Por favor aguarde, estamos conectando su línea...</p> )}
            <Spinner />
          </div>
        </div>
      )}
      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div className="mt-2">
            <h1>Líneas</h1>
            {/* {lines.length <= 0 && ( */}
              <input
                type="button"
                className="btn btn-default"
                value="Agregar nueva"
                onClick={() => launchWhatsAppSignup() }
              />
            {/* )} */}
          </div>

          <Header />
        </div>

        <header>
          <div style={{ float: "right" }}>
            <form className="form-inline">
              <div className="su-control my-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchQuery(e.target.value);
                    resetPage();
                  }}
                />
                <button
                  className="btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </form>
          </div>
        </header>

        <div className="container-fluid mt-3 px-0" style={{ height: "75%", overflowY: "auto", overflowX: "none" }}>
          <table className="table table-striped">
            <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
              <tr>
                <th>Acciones</th>
                <th>Nombre verificado</th>
                <th>Número de teléfono</th>
                <th>Nivel de calidad</th>
                <th>Límite de mensajes</th>
                <th>Fecha de conexión</th>
              </tr>
            </thead>
            <tbody>
              {lines.length > 0 && lines.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((line, k) => { 
                console.log('------line-----> ', line);
                
                return(
                <tr key={k}>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="dropdown">
                        <button
                          className="bg-transparent border-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <FaEllipsisVertical className="text-success f-24 pointer"/>
                        </button>
                        <div
                          className="dropdown-menu p-2"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button
                            className="text-primary border-0 bg-transparent d-block pointer mt-2"
                            onClick={() => {
                              toast(<Modal line={line} />, {
                                position: "top-center",
                                // autoClose: 10000,
                                autoClose: false,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: JSON.parse(isDark) ? "dark" : "light",
                                className: "custom-toast-container", // Estilos personalizados para el toast
                                bodyClassName: "custom-toast-body",
                              })
                            }}
                          >
                            <FaEye style={{fontSize: "18px",color: "#ff9317",}} />
                            <span className="text-dark ml-2 f-14">
                              Ver
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <label>
                      {line.name.length >= 35 ? line.name.substr(0, 35) + "..." : line.name}
                    </label>
                  </td>
                  <td>{line.line}</td>
                  <td style={{textAlign: "-webkit-center"}}>
                    {(() => {
                      const { text, color, icon } = getQualityRatingDisplay(line.quality);
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: color,
                            color: "#fff",
                            borderRadius: "20px",
                            // padding: "5px 10px",
                            fontWeight: "bold",
                            width: "50%",
                            height: "2.5rem"
                          }}
                        >
                          <span style={{ marginRight: "10px", fontSize: "16px", marginTop: "4px" }}>{icon}</span>
                          {text}
                        </div>
                      );
                    })()}
                  </td>
                  <td>{(() => {
                      const { text } = getCurrentLimitDisplay(line.currentLimit);
                      return (
                        <label>
                          {text}
                        </label>
                      );
                    })()}
                  </td>
                  <td>{dayjs(line.created).locale("es").format("DD MMMM YYYY")}</td>
                </tr>
              )})}
            </tbody>
          </table>
          {validation && (
            <div className="text-center mt-3">
              <div className="empty">
                <TbTemplate style={{ fontSize: "55px" }} />
                <h1>No tienes líneas conectadas</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Sincronizar"
                  onClick={() => getTemplatesFacebook()}
                />
              </div>
            </div>
          )}
        </div>

        <footer>
          <div className="row">
            <div className="col-md-8"></div>

            <div className="col-md-4">
              {/* Creación de la paginación simple */}
              <Pagination
                totalData={lines.length}
                byPage={byPage}
                setPage={setPage}
              />
            </div>
          </div>
        </footer>

        <ToastContainer 
          position="top-center"
          autoClose={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          theme={JSON.parse(isDark) ? "dark" : "light"}
          toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
          bodyClassName="custom-toast-body"
        />
      </div>
    </Fragment>
  );
};

export default LinesModule;
