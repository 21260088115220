import React, { Fragment, useState, useEffect, useRef } from "react";
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";
//# Components
import Navigation from "./Navigation";
import logoFooter from "../../imgs/logo-footer.png";
import useAPI from "../../hooks/useAPI";


const Layout = () => {
  const [isAuth, setIsAuth] = useState(undefined);
  const [newSesionPopup, setNewSesionPopup] = useState(false);
  const [socketConn, setSocketConn] = useState("");
  const navigate = useNavigate();
  const { urlAPI_2, urlSocket, fetchWithAuth} = useAPI();
  const timestampRef = useRef(0);
  const token = localStorage.getItem("USER_TOKEN");
  const user = localStorage.getItem("USER_NAME");
  const roleDescription = localStorage.getItem("USER_ROLEDESCRIPTION");
  const location = useLocation();
  const currentPath = location.pathname;

  const code_msg_admin = "0000000000";
  const code_msg_user = "1111111111";
  const code_msg_dashboard = "2222222222";
  const code_msg_system = "3333333333";

  useEffect(() => {
    const auth = async () => {
      if (token) {
        setIsAuth(true);
        check_sessions();
      } else {
        setIsAuth(false);
      }
    };
    auth();
  }, []);

  const check_sessions = async () => {
    console.log("Checking sessions...");
    const url = urlAPI_2 + `getSessions`;
    const options = { method: "POST", mode: "cors", body: JSON.stringify({agent: localStorage.getItem("USER_ID")})};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    if(result.connections_count > 0){
      setNewSesionPopup((value) => {return true});
    }else{
      connectI(code_msg_system);
    }

  }

  const handleCloseSession = async () => {
    const url = urlAPI_2 + `closeSessions`;
    const options = { method: "POST", mode: "cors", body: JSON.stringify({agent: localStorage.getItem("USER_ID"), timestamp: timestampRef.current})};
    const response = await fetchWithAuth(url, options);
  }
   
  const handleCloseCurrentSession = async () => {
    console.log("Removing USER_TOKEN because handleCloseCurrentSession");
    console.log("Una sesión nueva del usuario se está utilizando en otro navegador");
    localStorage.removeItem("USER_TOKEN");
    localStorage.removeItem("USER_NAME");
    localStorage.removeItem("USER_ROLEDESCRIPTION");
    localStorage.removeItem("USER_ROLEID");
    navigate("/");
  }

  const closeCurrentSession = async () => {
    const url = urlAPI_2 + "removeSystemConnections";
    let msg_send = {phone: `${code_msg_system}${timestampRef.current}`, agent: localStorage.getItem("USER_ID")};
    const options = { method: "POST", mode: "cors", body: JSON.stringify(msg_send)};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    console.log("RemoveSystemConnections results: ", result);
  }

  const useHere = async () => {
    connectI(code_msg_system);
    setNewSesionPopup(false); 
    handleCloseSession();
  }

  const connectI = (i) => {
    let timestamp = new Date().getTime();
    timestampRef.current = timestamp;
    localStorage.setItem("timestampRef", timestampRef.current);
    
    if (socketConn) {
      socketConn.close();
    }
    let socketConnAux = new WebSocket(urlSocket);
    socketConnAux.addEventListener("open", (e) => {
      const payload = {
        Action: "message",
        Msg: i,
      };
      try {
        socketConnAux.send(JSON.stringify(payload));
      } catch (error) {
        console.log("error ", error);
      }
    });

    socketConnAux.onmessage = async function (event) {
        const data_msg = JSON.parse(event.data);
        if (data_msg.message) {
            let aux = data_msg.message.split("####");
            let timestamp = timestampRef.current.toString();
            console.log("timestampRef.current: ", timestamp);
            console.log("USER_ID: ", localStorage.getItem("USER_ID"));
            console.log("aux: ", aux);
            if(aux.length !== 3 || aux[2] !== localStorage.getItem("USER_ID")){
              return;
            }
            console.log("Condicion: ", (aux[0] === "connectionIdSystem" && aux[1] !== timestamp))
            if(aux[0] === "connectionIdSystem" && aux[1] !== timestamp){
              await closeCurrentSession();
              await handleCloseCurrentSession();
            }else{
              const url = urlAPI_2 + "removeOtherSystemConnections";
              let msg_send = {phone: `${code_msg_system}${timestampRef.current}`, agent: localStorage.getItem("USER_ID")};
              const options = { method: "POST", mode: "cors", body: JSON.stringify(msg_send)};
              const response = await fetchWithAuth(url, options);
              const result = await response.json();
            }
        }else{
            const msg_send = {};
            msg_send.connectionId = `${event.data}`;
            msg_send.phone = `${code_msg_system}${timestampRef.current}`;
            msg_send.agent = localStorage.getItem("USER_ID");
            msg_send.status = true;
            const url = urlAPI_2 + "saveConnection";
            const options = { method: "POST", mode: "cors", body: JSON.stringify(msg_send)};
            const response = await fetchWithAuth(url, options);
            const result = await response.json();
        }
    };

    socketConnAux.onclose = async (event) => {
      console.log("Cerrando websocket system. Estado del websocket: ", event.target.readyState);
      await closeCurrentSession(); 
      connectI(code_msg_system);
    };

    setSocketConn(socketConnAux);

  };  


  if (isAuth === undefined) {
    return null;
  }

  if (!isAuth) {
    return <Navigate to="/log-in" />;
  }

  if (roleDescription === "SuperAdmin") {
    return <Navigate to="/admin" />;
  }


  return (
    <Fragment>
      {isAuth ? (
        <div className="pcoded-container navbar-wrapper">
          <Navigation user={user} roleDescription={roleDescription} />
          <div className="pcoded-main-container style-new m-0" id="main-content"  style={{left: "2.5%", width: "97.5%"}}>
            <div className="pcoded-wrapper">
              <div className="pcoded-content content-all h-100">
                <div className="pcoded-inner-content" style={{padding: "0"}}>
                  <div className="main-body h-100">
                    <div className="page-wrapper pb-0" style={{padding: currentPath == "/home/chat" ? "0" : "0 1rem"}}>
                      <div className="page-body h-100 response-chat">
                        <Outlet />

                        {newSesionPopup &&
                          <div className="image-preview-overlay">
                            <div className="image-preview-container">
                              <div className="content-modal-sesion bg-white p-2" style={{width: "700px", height: "auto", borderRadius: "7px"}}>
                                  <div className="p-3">
                                    <p className="text-justify" style={{fontSize: "1rem"}}>
                                      Tu cuenta se encuentra abierta en otra ventana. Haz click en &quot;Usar aquí&quot; para abrir en esta ventana.
                                    </p>
                                    <div className="d-flex justify-content-end mt-3">
                                      <a
                                        href="javascript:;"
                                        className="btn btn-lg btn-default mr-1"
                                        onClick={() => {setNewSesionPopup(false); handleCloseCurrentSession();}}
                                        >
                                        Cerrar
                                      </a>
                                      <input
                                        className="btn btn-lg btn-info"
                                        value="Usar aquí"
                                        type="button"
                                        onClick={() => {useHere();}}
                                      />
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/log-in" />
      )}
    </Fragment>
  );
};

export default Layout;
