import React, { useState, useEffect } from "react";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Toaster, toast } from "react-hot-toast";
import Spinner from "../plugins/Spinner";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Countries from "../plugins/Countries.json";
import Select from "react-select";
import TimezoneSelect from 'react-timezone-select'

import { BsPersonVcard, BsWhatsapp, BsBriefcase } from "react-icons/bs";
import { ImCopy } from "react-icons/im";
import { TbWorldPin } from "react-icons/tb";
import { BiSolidHelpCircle } from "react-icons/bi";
import { BsCheckCircle, BsExclamationCircle, BsXCircle, BsQuestionCircle } from "react-icons/bs";



const MyData = () => {
  const [companie, setCompanie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const [types, setTypes] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [companyPlan, setCompanyPlan] = useState([]);
  const [planName, setPlanName] = useState("");

  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [selectedCountry, setSelectedCountry] = useState("");
  const [errorCountrie, setErrorCountrie] = useState(false);

  const { urlAPI_2, fetchWithAuth } = useAPI();

  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({
    defaultValues: {
      name: "",
      identification: "",
      typeIdentification: "",
      email: "",
      idWspBusinessAcount: "",
      plan: "",
    },
  });

  useEffect(() => {
    getUsersSystem();
    getCompanie();
    getTypeIdentification();
  }, []);

  const getQualityRatingDisplay = (qualityRating) => {
    switch (qualityRating) {
      case "GREEN":
        return { text: "Alta", color: "#28a745", icon: <BsCheckCircle /> };
      case "YELLOW":
        return { text: "Media", color: "#ffc107", icon: <BsExclamationCircle /> };
      case "RED":
        return { text: "Baja", color: "#dc3545", icon: <BsXCircle /> };
      default:
        return { text: "Desconocida", color: "#6c757d", icon: <BsQuestionCircle /> };
    }
  };


  const getCurrentLimitDisplay = (currentLimit) => {
    switch (currentLimit) {
      case "TIER_50":
        return { text: "50"};
      case "TIER_250":
        return { text: "250"};
      case "TIER_1K":
        return { text: "1000"};
      case "TIER_10K":
        return { text: "10000"};
      case "TIER_100K":
        return { text: "100000"};
      case "TIER_UNLIMITED":
        return { text: "Ilimitado"};                        
      default:
        return { text: "Desconocido"};
    }
  };

  const getUsersSystem = async () => {
    try {
      // setLoading(true);
      const url = urlAPI_2 + "users";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API======>>", result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setValidation(true);
      } else {
        localStorage.setItem("ID_COMPANY", result.idCompany);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanie = async () => {
    const resultIdCompany = localStorage.getItem("ID_COMPANY");
    try {
      setLoading(true);
      const url = urlAPI_2 + "companies/" + resultIdCompany;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API GETCOMPANY----------======>> ", result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setValidation(true);
        setCompanie([]);
      } else {
        setCompanie(result.data[0]);
        setValidation(false);
        const {
          name,
          identification,
          documentTypeId,
          email,
          status,
          idPhone,
          tokenWhatsapp,
          idWspBusinessAcount,
          plan,
          planName,
          webhook,
          tokenWebhook,
          country,
          timezone,
          displayPhoneNumber,
          qualityRating,
          verifiedName,
          currentLimit
        } = result.data[0];
        if (country) {
          const countrieSelect = Countries.filter(countrie => countrie.name === country);
          setSelectedCountry(countrieSelect[0]);
        }
        if (timezone) {
          setSelectedTimezone(timezone)
        }
        if (plan) {
          setPlanName(planName);
          setCompanyPlan(planName)
          
        }
        reset({
            name,
            identification,
            typeIdentification: documentTypeId,
            email,
            status,
            idPhone,
            tokenWhatsapp,
            idWspBusinessAcount,
            plan,
            webhook,
            tokenWebhook,
            displayPhoneNumber,
            qualityRating,
            verifiedName,
            currentLimit,
          },
          { keepDefaultValues: true }
        );
      }
      setLoading(false);
    } catch (error) {
      console.log('---------->',error);
    }
  };

  const navigate = useNavigate();
  
  const getTypeIdentification = async () => {
    try {
      // setLoading(true);
      const url = urlAPI_2 + "typesDocument";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API: Get Types: Identification", result);
      setTypes(result.data);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const putCompany = async (inputs) => {
    const { name, identification, typeIdentification, email, idWspBusinessAcount, plan } = inputs;

    const valuess = {name, identification, typeIdentification, email, idWspBusinessAcount, plan};

    const id = companie._id;
    var values = inputs;
    values.country = selectedCountry.name
    values.timezone = selectedTimezone
    console.log("values: ", values);
    try {
      setLoading(true);
      const url = urlAPI_2 + "companies/" + id;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: Put Commpany", result);
      Swal.fire({
        title: "¡Bien!",
        title: result.message,
        icon: result.type,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Almacenar la zona horaria seleccionada
  const handleChangeTimezone = async (e) => {
    console.log('Zona horaria seleccionada::', e)
    setSelectedTimezone(e);
  }

  //#--> Funcion para almacenar el país
  const handleChangeCountries = (selectedCountry) => {
    console.log('Pais seleccionado=====>>', selectedCountry);
    setErrorCountrie(false);
    setSelectedCountry(selectedCountry);
  };


  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div>
            <h1>Mis datos</h1>
          </div>

          <Header />
        </div>

        <div id="content-info-company" className="container-fluid mt-3 px-0" style={{ height: "90%", overflowY: "auto" }}>
          <section className="module-create-user myData" style={{ height: "auto", backgroundColor: "transparent", boxShadow: "none" }}>
            <div>
              <form onSubmit={handleSubmit(putCompany)}>
                <div className="sections-my-data a">
                  <div className="header-data d-flex">
                    <h2 className="title w-50 d-flex align-items-centar" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                      <BsPersonVcard style={{ fontSize: "25px", marginRight: "10px" }} />
                      Información de la compañia
                    </h2>
                    <div className="plan w-50 d-flex align-items-center" style={{padding: "20px 20px 0px", marginBottom: "0.5rem"}}>
                      <h5 className="m-0 d-flex align-items-center">
                        <BsBriefcase style={{ fontSize: "25px", marginRight: "10px" }} /> Plan: <span className="ml-1" style={{fontWeight: "600"}}>{planName}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="module-section border-top-0" id="product-basic">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label htmlFor="select">Tipo de documento: </label>
                              <select
                                name="select"
                                id="select"
                                className="form-control"
                                {...register("typeIdentification", {
                                  required: {
                                    value: true,
                                    message:
                                      "El tipo de identificación es obligatoria.",
                                  },
                                })}
                              >
                                <option value="" key="0" disabled selected>
                                  Seleccione...
                                </option>
                                {types &&
                                  types.map((type) => (
                                    <option value={type._id} key={type._id}>
                                      {type.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>Numero de documento: </label>
                              <div className="su-control-group">
                                <input
                                  className="form-control"
                                  id="uuid"
                                  aria-readonly
                                  type="number"
                                  {...register("identification", {
                                    required: {
                                      value: true,
                                      message:
                                        "El numero de identificación es obligatorio.",
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>Nombre/Razón social : </label>
                              <div className="su-control-group">
                                <input
                                  className="form-control"
                                  id="companyName"
                                  aria-readonly
                                  type="text"
                                  {...register("name", {
                                    required: {
                                      value: true,
                                      message:
                                        "El nombre de la empresa es obligatorio.",
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>Correo electronico: </label>
                              <div className="su-control-group">
                                <input
                                  className="form-control"
                                  id="companyEmail"
                                  aria-readonly
                                  type="email"
                                  {...register("email", {
                                    required: {
                                      value: true,
                                      message:
                                        "El correo electrónico es requerido.",
                                    },
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message:
                                        "El formato del correo no es correcto",
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sections-my-data b">
                  <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                    <BsWhatsapp style={{ fontSize: "25px", marginRight: "10px" }} />
                    Información whatsapp
                  </h2>
                  <div className="module-section border-top-0" id="product-basic">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>ID teléfono: </label>
                              <div className="su-control-group">
                                <input
                                  className="form-control"
                                  id="idPhone"
                                  disabled
                                  type="text"
                                  value={companie.idPhone}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>Token whatsapp: </label>
                              <div className="su-control-group">
                                <input
                                  className="form-control"
                                  id="tokenWhatsapp"
                                  disabled
                                  type="text"
                                  value={companie.tokenWhatsapp}
                                  style={{ paddingRight: "91px" }}
                                />
                                <CopyToClipboard text={companie.tokenWhatsapp}>
                                  <a
                                    href="javascript:;"
                                    className="btn btn-lg btn-default mr-1 button-copy d-flex align-items-center"
                                    onClick={() => toast.success("Copiado!")}
                                  >
                                    <ImCopy style={{ fontSize: "20px", marginRight: "3px" }} /> Copiar
                                  </a>
                                </CopyToClipboard>

                                <Toaster />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>URL webhook: </label>
                              <div className="su-control-group">
                                <input
                                  className="form-control"
                                  id="uuid"
                                  name="uuid"
                                  disabled
                                  type="text"
                                  value={companie.webhook}
                                  style={{ paddingRight: "91px" }}
                                />
                                <CopyToClipboard text={companie.webhook}>
                                  <a
                                    href="javascript:;"
                                    className="btn btn-lg btn-default mr-1 button-copy d-flex align-items-center"
                                    onClick={() => toast.success("Copiado!")}
                                  >
                                    <ImCopy style={{ fontSize: "20px", marginRight: "3px" }} /> Copiar
                                  </a>
                                </CopyToClipboard>

                                <Toaster />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>Token webhook: </label>
                              <div className="su-control-group">
                                <input
                                  className="form-control"
                                  id="tokenWebhook"
                                  name="tokenWebhook"
                                  disabled
                                  type="text"
                                  value={companie.tokenWebhook}
                                  style={{ paddingRight: "91px" }}
                                />
                                <CopyToClipboard text={companie.tokenWebhook}>
                                  <a
                                    href="javascript:;"
                                    className="btn btn-lg btn-default mr-1 button-copy d-flex align-items-center"
                                    onClick={() => toast.success("Copiado!")}
                                  >
                                    <ImCopy style={{ fontSize: "20px", marginRight: "3px" }} /> Copiar
                                  </a>
                                </CopyToClipboard>

                                <Toaster />
                              </div>
                            </div>
                          </div>
                          
                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>ID cuenta whatsapp business: </label>
                              <div className="su-control-group">
                                <input
                                  className="form-control"
                                  id="idWspBusinessAcount"
                                  type="text"
                                  // value={companie.idWspBusinessAcount}
                                  {...register("idWspBusinessAcount", {
                                    required: {
                                      value: true,
                                      message:
                                        "El id cuenta whatsapp business es obligatorio.",
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>Nombre verificado: </label>
                              <div className="su-control-group">
                                <input
                                    className="form-control"
                                    id="verifiedName"
                                    type="text"
                                    disabled
                                    value={companie.verifiedName}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>Número de teléfono: </label>
                              <div className="su-control-group">
                                <input
                                    className="form-control"
                                    id="displayPhoneNumber"
                                    type="text"
                                    disabled
                                    value={companie.displayPhoneNumber}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>Nivel de calidad: </label>
                              <div className="su-control-group">
                                {(() => {
                                  const { text, color, icon } = getQualityRatingDisplay(companie.qualityRating);
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        backgroundColor: color,
                                        color: "#fff",
                                        borderRadius: "20px",
                                        padding: "5px 10px",
                                        fontWeight: "bold",
                                        width: "50%",
                                      }}
                                    >
                                      <span style={{ marginRight: "10px", fontSize: "16px", marginTop: "4px" }}>{icon}</span>
                                      {text}
                                    </div>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                              <label>Límite de mensajes: </label>
                              <div className="su-control-group">
                                {(() => {
                                  const { text } = getCurrentLimitDisplay(companie.currentLimit);
                                  return (
                                    <input
                                        className="form-control"
                                        id="currentLimit"
                                        type="text"
                                        disabled
                                        value={text}
                                    />
                                  );
                                })()}
                              </div>
                            </div>
                          </div>                          
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <footer className="mx-0">
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6 right">
                        <input
                          className="btn btn-lg btn-info"
                          value="Actualizar"
                          type="submit"
                        />
                      </div>
                    </div>
                  </footer> */}
                </div>

                <div className="sections-my-data c">
                  <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                    <TbWorldPin style={{ fontSize: "25px", marginRight: "10px" }} />
                    Zona horaria
                  </h2>
                  <div className="module-section border-top-0" id="product-basic">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable ">
                              <label>País:</label>
                              <Select
                                className="form-control select-timezone"
                                placeholder="Seleciona un país"
                                value={selectedCountry}
                                onChange={handleChangeCountries}
                                options={Countries}
                                // getOptionLabel={(option) => option.name}
                                getOptionLabel={(option) => (
                                  <div className="option d-flex" style={{fontSize: "15px"}}>
                                    <img src={option.flag} alt={option.name} width="20" style={{width: "27px", marginRight: "10px"}} />{" "} {option.name} 
                                  </div>
                                )}
                                getOptionValue={(option) => option.name}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable ">
                              <label>Zona horaria:</label>
                              <TimezoneSelect
                                className="form-control select-timezone"
                                value={selectedTimezone}
                                onChange={(e) => handleChangeTimezone(e)}
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className="mx-0">
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6 right">
                        <input
                          className="btn btn-lg btn-info"
                          value="Actualizar"
                          type="submit"
                        />
                      </div>
                    </div>
                  </footer>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>

    </>
  );
};

export default MyData;