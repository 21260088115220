import React, { useState, useEffect, useRef } from "react";
import Header from "../layout/Header";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Spinner from "../plugins/Spinner";
import Swal from "sweetalert2";
import useAPI from "../../hooks/useAPI";
import Modal from "../layout/Modal";
import dayjs from "dayjs";
import { upload_s3 } from './UploaderS3';
import Select from "react-select";

import { BsSearch, BsPencilFill, BsTrash3, BsFillFileEarmarkPdfFill, BsFillFileEarmarkWordFill, BsFillFileEarmarkFill, BsFillFileEarmarkPptFill, BsFillFileEarmarkFontFill, BsPlusSquareDotted } from "react-icons/bs";
import { FaSortUp, FaSortDown, FaEye } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { GiBrainFreeze } from 'react-icons/gi';
import { SiGooglesheets } from 'react-icons/si';
import Pagination from "../plugins/Pagination";
import usePage from "../../hooks/usePage";


const Assistants = () => {
  const [loading, setLoading] = useState(false);
  const [modalCreateAssistant, setModalCreateAssistant] = useState(false);
  const [listAssistants, setListAssistants] = useState([]);
  const [listBots, setListBots] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [templateSelected, setTemplateSelected] = useState({});
  const [hourSelected, setHourSelected] = useState({});
  const [editTemplateSelected, setEditTemplateSlected] = useState();
  const [validation, setValidation] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("");
  const [navAdditionalData, setNavAdditionalData] = useState(false);
  const [modeUpdate, setModeUpdate] = useState(false);
  const [idAssistant, setIdAssistant] = useState("");
  const [linksDocument, setlinksDocument] = useState("")
  const [sizeMax, setSizeMax] = useState(false);
  const [isFile, setIsFile] = useState("");
  const [typeArea, setTypeArea] = useState("");
  const [agentArea, setAgentArea] = useState("");
  const [isDark, setIsDark] = useState(false);
  const [listAreas, setListAreas] = useState([]);
  const [listAdvisers, setListAdvisers] = useState([]);
  const [transferCheck, setTransferCheck] = useState(false);
  const [isAgentArea, setIsAgentArea] = useState(false);
  const [webhookCheck, setWebhookCheck] = useState(false);

  const fileInputRef = useRef(null);

  const { urlAPI_1, urlAPI_2, urlAPI_3, fetchWithAuth } = useAPI();
  const { page, setPage, byPage, resetPage } = usePage();
  const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm({});

  const radioOptions = [
    {id: "1", name: "Digitar instrucciones"},
    {id: "2", name: "Hoja de Google Sheet"}
  ]
  
  const radioOptions2 = [
    {id: "1", name: "Agentes"},
    {id: "2", name: "Areas"}
  ]

  const hoursSchduleOptions = [
    {value: "3600000", label: "1 hora antes"},
    {value: "7200000", label: "2 horas antes"},
    {value: "21600000", label: "6 horas antes"},
    {value: "43200000", label: "12 horas antes"},
    {value: "86400000", label: "24 horas antes"}
  ];

  const msg = {
    role: "Eres un asistente de atención al cliente muy útil, importante y profesional.",
    instructions_message: "Tu objetivo es proporcionar respuestas claras y precisas, resolver problemas de manera efectiva y asegurarte de que los clientes se sientan valorados y satisfechos con la atención recibida. Siempre actúas con empatía, cortesía y eficiencia para mejorar la experiencia del cliente.",
    messageTransfer: "Espera un momento, ya te atiende uno de nuestros agentes personalizados😎.",
  }

  useEffect(() => {
    getAssistants(true);
    getAreas();
    getAgents();
    getTemplates();
  }, []);

  //#--> Listar asistentes
  const getAssistants = async (getBotsFetch = false) => {
    try {
      setLoading(true);
      const url = urlAPI_3 + "assistants";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: getAssistants", result);
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setListAssistants([])
        setValidation(true)
      } else {
        setListAssistants(result.data)
        setValidation(false)
      }
      setLoading(false);

      if (getBotsFetch) {
        await getBots();
      }
    } catch (error) {
      console.log('error---->', error);
    }
  };

  //#--> Crear asistente AI
  const postAssistant = async (data) => {
    console.log('DATA a CREAR 🆕:', data)
    
    let objData = data;
    if(!data.language) {
      objData.role = msg.role
      objData.instructions_message = msg.instructions_message
      objData.language = "1"
    }
    objData.files = objData.file && linksDocument ? linksDocument : []
    

    let objTransfer = agentArea || {};
    objTransfer.isTransfer = objData.transferCheck
    
    if (data.transferCheck) {
      objTransfer.message = objData.messageTransfer
      if(agentArea === "") {
        setIsAgentArea(true)
        return
      }
    }
    objData.transfer = objTransfer;

    let objSchedule = {}
    objSchedule.isSchedule = objData.webhookCheck
    if (data.webhookCheck) {
      objSchedule.webhook = objData.webhookData
      objSchedule.webhookDataSearchEvents = objData.webhookDataSearchEvents;
      objSchedule.webhookDataCancelEvent = objData.webhookDataCancelEvent;
    }
    objData.schedule = objSchedule

    console.log('template',templateSelected);
    if (objData.schedule.isSchedule && !templateSelected) {
      Swal.fire({
        title: "¡Atención!",
        text: `Debes seleccionar una plantilla que cumpla con los requisitos en el Mensaje de Recordatorio.`,
        icon: 'error',
      });
      return;
    };

    if (objData.schedule.isSchedule && !hourSelected) {
      Swal.fire({
        title: "¡Atención!",
        text: `Debes seleccionar una hora para el envio del recordatorio.`,
        icon: 'error',
      });
      return;
    };

    if (objData.schedule.isSchedule && hourSelected) objData.schedule.reminderTime = hourSelected;    

    if (objData.schedule.isSchedule) {
      const { text } = templateSelected.content.find(item => item.type === 'BODY');
      const isValidTemplate = text.match(/{{(.*?)}}/g);
      console.log('❌❌❌❌', isValidTemplate);
      if (!isValidTemplate) {
        Swal.fire({
          title: "¡Atención!",
          text: `La plantilla seleccionada ${templateSelected.name}, no posee los párametros requeridos para el recordatorio de agendamiento. Por favor, seleccione otra.`,
          icon: 'error',
        });
        return;
      };

      if (isValidTemplate) {
        const parameters = isValidTemplate.map(match => match.replace(/{{|}}/g, ''));    
        console.log('lengthhh',parameters.length);
        if (parameters.length !== 4) {
          Swal.fire({
            title: "¡Atención!",
            text: `La plantilla seleccionada ${templateSelected.name}, no posee los párametros requeridos para el recordatorio de agendamiento. Por favor, seleccione otra.`,
            icon: 'error',
          });
          return;
        };
        objData.schedule.templateSchedule = templateSelected;
      };
    };

    delete objData.file;
    delete objData.transferCheck;
    delete objData.messageTransfer;
    delete objData.webhookCheck;
    delete objData.webhookData;
    delete objData.webhookDataSearchEvents;
    delete objData.webhookDataCancelEvent;

    console.log('DATA a CREAR FINALLLLLLLLLLLLLLLLLL🆕:', objData)
    try {
      setLoading(true);
      const url = urlAPI_3 + "createAssistant";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(objData)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: 🤖createAssistant:", result);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      setModalCreateAssistant(false)
      setLoading(false);
      getAssistants()
    } catch (error) {
      console.log('error al crear Asistente---->', error);
    }
  }

  //#--> Actualizar asistente AI
  const putAssistant = async (data) => {
    console.log('DATA a ACTUALIZAR:', data)
    console.log('data.file.length::::', data.file.length)
    console.log('linksDocument::::', linksDocument)
    console.log('isFile::::', isFile)
    
    let objData = data;
    if (objData.file || objData.file.length > 0 || isFile) {
      console.log('::ENTRO AL IF::')
      // objData.file = linksDocument.length > 0 ? linksDocument : isFile
      objData.files = isFile
    }
    // objData.transfer = agentArea;
    let objTransfer = agentArea || {};
    objTransfer.isTransfer = objData.transferCheck
    
    if (data.transferCheck) {
      objTransfer.message = objData.messageTransfer
      if(agentArea === "") {
        setIsAgentArea(true)
        return
      }
    }
    objData.transfer = objTransfer;

    let objSchedule = {}
    objSchedule.isSchedule = objData.webhookCheck
    if (data.webhookCheck) {
      objSchedule.webhook = objData.webhookData
      objSchedule.webhookDataSearchEvents = objData.webhookDataSearchEvents
      objSchedule.webhookDataCancelEvent = objData.webhookDataCancelEvent
    }
    objData.schedule = objSchedule

    if (objData.schedule.isSchedule && !templateSelected) {
      Swal.fire({
        title: "¡Atención!",
        text: `Debes seleccionar una plantilla que cumpla con los requisitos en el Mensaje de Recordatorio.`,
        icon: 'error',
      });
      return;
    };

    if (objData.schedule.isSchedule && !hourSelected) {
      Swal.fire({
        title: "¡Atención!",
        text: `Debes seleccionar una hora para el envio del recordatorio.`,
        icon: 'error',
      });
      return;
    };

    if (objData.schedule.isSchedule && hourSelected) objData.schedule.reminderTime = hourSelected; 

    if (objData.schedule.isSchedule && templateSelected) {
      const { text } = templateSelected.content.find(item => item.type === 'BODY');
      const isValidTemplate = text.match(/{{(.*?)}}/g);
      console.log('❌❌❌❌', isValidTemplate);
      if (!isValidTemplate) {
        Swal.fire({
          title: "¡Atención!",
          text: `La plantilla seleccionada ${templateSelected.name}, no posee los párametros requeridos para el recordatorio de agendamiento. Por favor, seleccione otra.`,
          icon: 'error',
        });
        return;
      };

      if (isValidTemplate) {
        const parameters = isValidTemplate.map(match => match.replace(/{{|}}/g, ''));    
        console.log('lengthhh',parameters.length);
        if (parameters.length !== 4) {
          Swal.fire({
            title: "¡Atención!",
            text: `La plantilla seleccionada ${templateSelected.name}, no posee los párametros requeridos para el recordatorio de agendamiento. Por favor, seleccione otra.`,
            icon: 'error',
          });
          return;
        };
        objData.schedule.templateSchedule = templateSelected;
      };
    };

    delete objData.file;
    delete objData.transferCheck;
    delete objData.messageTransfer;
    delete objData.webhookCheck;
    delete objData.webhookData;
    delete objData.webhookDataSearchEvents;
    delete objData.webhookDataCancelEvent;

    console.log('data FINAL::::', objData)
    try {
      setLoading(true);
      const url = urlAPI_3 + "assistant/"+idAssistant;
      const options = { method: "POST", mode: "cors", body: JSON.stringify(objData)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: updateAssistant/{id}:", result);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      setModalCreateAssistant(false);
      setLoading(false);
      setIsFile([])
      getAssistants();
    } catch (error) {
      console.log('error al crear Asistente---->', error);
    }
  }

  //#--> Eliminar asistente AI
  const deleteAssistant = async (id) => {
    const { name } = listAssistants.find(assistant => assistant.id === id);
    
    // Obtengo solo los chatbots que tengan un asistente activo, y que coincidan con el id que se pretende eliminar
    const isAssistantChatbotEnabled = [];
    listBots.forEach(bot => {
      if (bot.nodes?.some(node => node.type === "assistantAI" && node.data.assistant.id === id)) {
        isAssistantChatbotEnabled.push(bot);
      }
    });

    // Si el asistente está siendo usaod por algún BOT, no se puede eliminar
    if (isAssistantChatbotEnabled.length > 0) {
      const namesBotsActive = isAssistantChatbotEnabled.map(bot => (bot.name));
      const messageInfo = `El asistente ${name}, está siendo usado por ${namesBotsActive.length === 1 ? 'el siguiente bot:' : 'los siguientes bots:'} ${namesBotsActive.join(', ')}. No se puede eliminar.`;

      Swal.fire({
        title: "¡Ocurrió un error!",
        // text: messageInfo,
        icon: "warning",
        html: `
          El asistente <b>${name}</b>, está siendo usado por
          ${namesBotsActive.length === 1 ? 'el siguiente bot:' : 'los siguientes bots:'} <b> ${namesBotsActive.join(', ')}</b>.
          No se puede eliminar.
        `,
      });
      return;
    };

    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar este asistente?",
        text: "No podrás recuperarlo.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_3 + "assistant/"+id;
          const options = { method: "DELETE", mode: "cors"};
          const response = await fetchWithAuth(url, options);          
          const result = await response.json();
          console.log("Result of the API: deleteAssistant/{id}:", result);
          setModalCreateAssistant(false)
          setLoading(false);
          getAssistants()
          Swal.fire({
            title: "¡Bien!",
            title: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log('error al crear Asistente---->', error);
    }    
  }

  //#--> Eliminar archivo del asistente
  const onDeleteFile = (name, id) => {
    console.log('---name--->', name);
    console.log('---id--->', id);
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar "+name+"?",
        text: "No podrás recuperar el archivo y tendrias que cargarlo de nuevo.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let resApi = ""
          if (id) {
            setLoading(true);
            const url = urlAPI_3 + "file/"+id;
            const options = { method: "DELETE", mode: "cors"};
            const response = await fetchWithAuth(url, options);          
            resApi = await response.json();
            console.log("Result of the API: file/{id}:", resApi);
            setLoading(false);
          }
          Swal.fire({
            title: "¡Bien!",
            title: resApi?.message ?? "Se eliminó el registro exitosamente.",
            icon: resApi?.type ?? "success",
            confirmButtonText: "Aceptar",
          });
          const filt = isFile.filter((file) => {return file.name != name});
          console.log('newList:::', filt);
          setIsFile(filt);
        }
      });
    } catch (error) {
      console.log('error eliminar archivo del asistente---->', error);
    } 
  }

  //#--> Listar Bots
  const getBots = async () => {
    console.log('trayendo bots')
    try {
      setLoading(true);
      const url = urlAPI_3 + 'chatBot';
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(result.length <= 0) {
        console.log("no hay data");
        setListBots([]);
        setValidation(true);
      } else {
        console.log("si hay lista", result);
        setListBots(result);
        setValidation(false);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    } finally {
      console.log('🎁🎁🎁🎁🎁🎁')
      setLoading(false);
    };
  };

  //#--> Funcion al presionar editar un asistente
  const onEditAssistant = async (id) => {
    setModalCreateAssistant(true)
    setModeUpdate(true)
    const filterAssistant = listAssistants.find( a => a.id === id)
    setAgentArea(filterAssistant.transfer);
    setTemplateSelected(filterAssistant.schedule.templateSchedule);
    setHourSelected(filterAssistant.schedule.reminderTime);
    console.log('----------->', filterAssistant)
    reset({
      name: filterAssistant.name,
      instructions: filterAssistant.url ? "2" : "1",
      url: filterAssistant.url ?? "",
      file: filterAssistant.files,
      content: filterAssistant.content,
      language: filterAssistant.language,
      role: filterAssistant.role,
      instructions_message: filterAssistant.instructions_message,
      transfer: filterAssistant.transfer.type,
      transferCheck: filterAssistant.transfer.isTransfer,
      messageTransfer: filterAssistant.transfer.message || msg.messageTransfer,
      webhookCheck: filterAssistant.schedule.isSchedule,
      webhookData: filterAssistant.schedule.webhook,
      webhookDataSearchEvents: filterAssistant.schedule.webhookDataSearchEvents,
      webhookDataCancelEvent: filterAssistant.schedule.webhookDataCancelEvent
    })
    setIsFile(filterAssistant.files ?? "")
    setSelectedRadio(filterAssistant.url ? "2" : "1")
    setIdAssistant(id)
    setTransferCheck(filterAssistant.transfer.isTransfer)
    setWebhookCheck(filterAssistant.schedule.isSchedule)
    filterAssistant.transfer.type === "agent" ? setTypeArea("1") : setTypeArea("2")
  }

  //#--> Funcion al presionar agregar un nuevo asistente
  const onNewAssistant = async () => {
    reset({
      name: "",
      instructions: "",
      content: "",
      url: "",
      role: msg.role,
      instructions_message: msg.instructions_message,
      language: "1",
      messageTransfer: msg.messageTransfer,
      transferCheck: false,
      webhookCheck: false,
      webhookData: "",
      webhookDataSearchEvents: "",
      webhookDataCancelEvent: ""
    })
    setSelectedRadio("")
    setNavAdditionalData(false)
    setModalCreateAssistant(true)
    setModeUpdate(false)
    setTransferCheck(false)
    setTypeArea("1")
    setTemplateSelected("");
    setHourSelected("");
    setEditTemplateSlected("");
    setWebhookCheck(false)
    setlinksDocument("")
    setIsFile("")
  }

  const getSignedUrl = async (key_name, size) => {
    try {
      key_name = encodeURIComponent(key_name);
      const url = urlAPI_1 + `getSignedURL/${key_name}/${size}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      if(response.status == 200){
          let url_signed = await response.json();
          if(url_signed.error){
              return false;
          }
          return url_signed;
      }else{
          console.error('Error getting signed URL');
          return false;
      }
      return response;
    } catch (error) {
      console.error('Error getting signed URL:', error);
      return false;
    }
  };

  const uploadToS3 = async (file) => {
    try {
      let max_size = false;
      if(file.type.includes("document") && file.size > ((1024 * 1024) * 100)){
        max_size = true;
        Swal.fire({
          title: "¡Atención!",
          text: "El archivo supera el peso máximo permitido para documentos: 100MB. Intente enviarlo como documento.",
          icon: "info",
        });
      }

      if (max_size) {
        setSizeMax(true);
        return false;
      } else {
        setSizeMax(false);
      }
      setLoading(true);
      const signedUrl = await getSignedUrl(file.name, file.size);
      if(signedUrl){
        const res = await upload_s3(file, signedUrl);
        console.log('res res::', res);
        setLoading(false);
        return res;
      }
      setLoading(false);
    } catch (error) {
      console.error('Error uploading to s3...', error);
      return false;
    }
  };

  //Funcion que obtiene las plantillas
  const getTemplates = async () => {
    try {
      const url = urlAPI_2 + "templates";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Plantillas========>>", result);
      const listTemplates = result.data;
      if (result.type !== "success") {
        setTemplates([]);
      } else {
        const isTemplates = listTemplates.filter(tem => tem.status === "APROBADA" && tem.isActive === true);
        console.log('Lista modificada de plantillas=====>', isTemplates)
        setTemplates(isTemplates);
      }
    } catch (error) {
      console.log("error al consultar las plantillas", error);
      return null;
    }
  };

  //#--> Cuando seleccionamos una plantilla
  const handleChange = (event) => {
    console.log('⭐⭐⭐⭐⭐', event);
    setTemplateSelected(event);
  };

  const handleChangeHours = (event) => {
    console.log('⭐⭐⭐⭐⭐', event);
    setHourSelected(event);
  };

  //Convertit documento para guardar
  const onFile = async (e) => {
    console.log("Recibiendo los archivos", e.target.files);
    var files = e.target.files;
    let links = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      console.log("file", file);
      const types = ["application/pdf", "text/plain", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];
      if (types.includes(file.type)) {
        const link = await uploadToS3(file);
        const name = file.name
        const obj = { 
          id: "", 
          name: name, 
          url: link 
        }
        links.push(obj);
      } else {
        Swal.fire({
          title: "¡Atención!",
          text: "Recuerda que el/los archivo/s debe/n ser pdf, txt, word o powerpoint.",
          icon: "info",
        });
      }
    }
    console.log('links:::', links);
    console.log('isFile:::', isFile);
    setlinksDocument(links);
    setIsFile((prev) => [...prev, ...links])
  };

  // Obtiene la lista de areas
  const getAreas = async () => {
    try {
      const url = urlAPI_2 + "getAreasByCompany";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
        setListAreas([]);
      }else{
        const opts = result.data.map(v => {
          return {
            label: v.description,
            value: v._id,
            type: "area"
          }
        })
        setListAreas(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Obtiene la lista de agentes
  const getAgents = async () => {
    try {
      const url = urlAPI_2 + "usersbyArea";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      } else if (result.data.length <= 0) {
        setListAdvisers([]);
      } else {
        const opts = result.data.map(v => {
          return {
            label: v.name,
            value: v._id,
            type: "agent"
          }
        })
        setListAdvisers(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };
  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formatAssistants = [...listAssistants].reverse();

  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div className="mt-2">
            <h1>Asistentes</h1>
            <input
              type="button"
              className="btn btn-default"
              value="Agregar nuevo"
              onClick={() => onNewAssistant()}
            />
          </div>
          <Header />
        </div>

        <header>
          <div style={{ float: "right" }}>
            <form className="form-inline">
              <div className="su-control my-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  // value={search}
                  // onChange={(e) => {
                  //   setSearch(e.target.value);
                  //   searchQuery(e.target.value);
                  //   resetPage();
                  // }}
                />
                <button
                  className="btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </form>
          </div>
        </header>

        <div className="container-fluid mt-3 px-0" style={{ height: "75%", overflowY: "auto", overflowX: "none" }}>
          <table className="table table-striped">
            <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
              <tr>
                <th>Acciones</th>
                <th>Nombre</th>
                <th>Estado</th>
                <th>Creado</th>
                <th>Actualizado</th>
              </tr>
            </thead>
            <tbody>
              {formatAssistants.length > 0 && formatAssistants.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((assistant, k) => (
                <tr key={k}>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="dropdown">
                        <button className="bg-transparent border-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <FaEllipsisVertical className="text-success f-24 pointer"/>
                        </button>
                        <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
                          <button className="text-primary border-0 bg-transparent d-block pointer mt-2" onClick={() => onEditAssistant(assistant.id)}>
                            {/* <i className="icon-pencil" style={{ fontSize: "18px" }} /> */}
                            <BsPencilFill style={{ fontSize: "18px" }}/>
                            <span className="text-dark ml-2 f-14">Editar</span>
                          </button>
                          <button className="text-danger border-0 bg-transparent d-block pointer mt-2" onClick={() => deleteAssistant(assistant.id)}>
                            {/* <i className="icon-trash-empty" style={{ fontSize: "18px" }}/> */}
                            <BsTrash3 style={{ fontSize: "18px" }}/>
                            <span className="text-dark ml-2 f-14">Eliminar</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{assistant.name.length >= 35 ? assistant.name.substr(0, 35) + "..." : assistant.name}</td>
                  <td className="status-template">
                    <p>
                      <a
                        href="javascript:;"
                        className="green"
                      >
                        Activo
                      </a>
                    </p>
                  </td>
                  <td>{dayjs(assistant.created).locale("es").format("DD MMMM YYYY h:mm A")}</td>
                  <td>{dayjs(assistant.updated).locale("es").format("DD MMMM YYYY h:mm A")}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {validation && (
            <div className="text-center mt-3">
              <div className="empty">
                <GiBrainFreeze style={{ fontSize: "55px" }} />
                <h1>No tienes Asistentes</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Agregar nuevo"
                  onClick={() => onNewAssistant()}
                />
              </div>
            </div>
          )}
        </div>

        <footer>
          <div className="row">
            <div className="col-md-8"></div>

            <div className="col-md-4">
              {/* Creación de la paginación simple */}
              <Pagination
                totalData={formatAssistants.length}
                byPage={byPage}
                setPage={setPage}
              />
            </div>
          </div>
        </footer>
      </div>

      <Modal
        status={modalCreateAssistant}
        changeStatus={setModalCreateAssistant}
        title={`${modeUpdate ? "Actualizar" : "Entrenar"} asistente`}
        width={"750px"}
      >
        <Content>
          <form onSubmit={handleSubmit(modeUpdate ? putAssistant : postAssistant)} style={{ width: "100%" }}>
            <div className="scrollable-content scrollable-content-overflow">
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Nombre: </label>
                <label className="f-w-100 font-italic d-block" >Escribe el nombre de tu asistente (Solo tú lo verás).</label>
                <div className="su-control-group">
                  <input
                    className="form-control px-2"
                    type="text"
                    name="name"
                    {...register("name", {
                      required: {
                        value: true,
                        message:"El nombre es requerido.",
                      }
                    })}
                  />
                </div>
                {errors.name && <Alert>* {errors.name.message}</Alert>}
              </div>

              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Instrucciones: </label>
                <label className="f-w-100 font-italic d-block" >Selecciona como deseas cargar las instrucciones para entrenar tu asistente.</label>
                <div className="su-control mt-2 d-flex justify-content-evenly">
                  {radioOptions.length > 0 && radioOptions.map((radio, k) => (
                    <div key={k} className="d-flex pointer">
                      <input
                        className="pointer"
                        type="radio"
                        name="answerSettings"
                        id={radio.id}
                        value={radio.id}
                        checked={selectedRadio === radio.id}
                        {...register("instructions", {
                          required: {
                            value: true,
                            message:"El campo es requerido.",
                          }
                        })}
                        onChange={(e) => {
                          console.log('-------radio--------', e.target.value)
                          setSelectedRadio(e.target.value);
                          if (e.target.value === 2) {
                            // reset({content: ""})
                            setValue('content', "");
                          } else {
                            // reset({url: ""})
                            setValue('url', "");
                          }
                        }}
                      />
                      <label className="d-flex mb-0 ml-1 pointer" htmlFor={radio.id} key={radio.id} style={{ padding: "5px 2px 5px" }}>
                        {radio.name}
                      </label>
                    </div>
                  ))}
                </div>
                {errors.instructions && <Alert>* {errors.instructions.message}</Alert>}
              </div>

              { selectedRadio === "1" ? (
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <div className="su-control-group">
                    <textarea
                      className="form-control px-2"
                      placeholder="Eres un asistente de gran ayuda..."
                      rows="4"
                      type="text"
                      name="content"
                      // value={inputStr2}
                      {...register("content", {
                          required: {
                            value: true,
                            message:"El campo es requerido.",
                          },
                          maxLength: {
                            value: 240000,
                            message: "El máximo es de 240.000 caracteres.",
                          }
                        })}
                      // onChange={handleChangeQuiclyReply}
                    ></textarea>                    
                  </div>
                  {errors.content && <Alert>* {errors.content.message}</Alert>}
                </div>
              ) : selectedRadio === "2" ? (<>
                <div className="d-flex mt-3 justify-content-center">
                  <a 
                    className="btn btn-lg btn-info d-flex align-items-center justify-content-center" 
                    href="https://docs.google.com/spreadsheets/d/16qe4A5CRCNEcTCyfGy0k6njBvTGlcmx8fownzryQB6I/edit?usp=sharing" 
                    target="_blanck"
                    // style={{width:"70%"}}
                  >
                    <SiGooglesheets className="mr-1" style={{fontSize:"1.5em"}}/>
                    <span>Ver Plantilla de ejemplo (Google Sheets)</span>
                  </a>
                </div>

                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  {/* <label>Nombre: </label> */}
                  <div className="su-control-group">
                    <input
                      className="form-control px-2"
                      placeholder="URL de la Hoja de Google Sheet (Debe estar pública)"
                      type="text"
                      name="url"
                      {...register("url", {
                        required: {
                          value: true,
                          message:"El campo es requerido.",
                        },
                        pattern: {
                          value: /^(https?:\/\/)?(www\.)?docs\.google\.com\/spreadsheets\/[a-zA-Z0-9-_]+\/?.*$/,
                          message: "URL inválida.",
                        }
                      })}
                    />
                  </div>
                  {errors.url && <Alert>* {errors.url.message}</Alert>}
                  <label className="f-w-100 font-italic" >Aquí debe ir la URL de la Hoja de Google que contiene las instrucciones para el asistente.<br/> 
                    <strong>Impotante:</strong> La hoja debe ser PUBLICA.
                  </label>
                </div>
              </>) : null
              }

              <div className="panel-heading my-3" style={{border: "1px solid #e8e8e8", boxShadow:"2px 2px 0px 0px #b7c6ca"}}>
                <label 
                  id="title-aditionalDates" 
                  className="panel-title d-flex justify-content-between align-items-center pointer mb-0 p-2" 
                  onClick={() => {setNavAdditionalData(!navAdditionalData)}}
                >
                  <a href="javascript:;" className={`d-flex align-items-center a-payment ${navAdditionalData && "active"}`}>
                    Opciones avanzadas (Opcional)
                  </a>
                  {navAdditionalData ?
                    <FaSortUp className="pointer mr-3" style={{ fontSize: "20px", marginTop: "5px" }}/> :
                    <FaSortDown className="pointer mr-3" style={{ fontSize: "20px", marginBottom: "5px" }}/>
                  }
                </label>

                {navAdditionalData ? (
                  <div id="email-welcome" className="panel-collapse">
                    <div className="panel-body">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <div className="mb-2 px-4">
                          <label>Idioma del bot: </label>
                          <div className="su-control-group">
                            <select
                              name="language"
                              id="language"
                              className="form-control px-2"
                              {...register("language")}
                            >
                              <option value="1" key="1" >
                                Español
                              </option>
                              <option value="2" key="2" >
                                Ingles
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="additionalOption">
                          <label>Rol del Bot: </label>
                          <label className="f-w-100 font-italic d-block" >Este será el papel que tomará el asistente. Puedes dejarlo así, si no sabes que escribir.</label>
                          <div className="su-control-group">
                            <textarea
                              className="form-control px-2"
                              placeholder="Eres un asistente de gran ayuda..."
                              rows="3"
                              type="text"
                              name="role"
                              // value={inputStr2}
                              {...register("role", {
                                required: {
                                  value: true,
                                  message: "El campo es requerido.",
                                },
                                maxLength: {
                                  value: 300,
                                  message: "El máximo es de 300 caracteres.",
                                }
                              })}
                            ></textarea>
                            {errors.role && <Alert>* {errors.role.message}</Alert>}                    
                          </div>
                        </div>

                        <div className="additionalOption">
                          <label>Instrucciones del Mensaje: </label>
                          <label className="f-w-100 font-italic d-block" >Estas son las instrucciones que usamos para que ChatGPT procese el mensaje. Si no tienes una alternativa, por favor, déjalo sin modificar.</label>
                          <div className="su-control-group">
                            <textarea
                              className="form-control px-2"
                              placeholder="Eres un asistente de gran ayuda..."
                              rows="5"
                              type="text"
                              name="instructions_message"
                              // value={inputStr2}
                              {...register("instructions_message", {
                                required: {
                                  value: true,
                                  message: "El campo es requerido.",
                                },
                                maxLength: {
                                  value: 3000,
                                  message: "El máximo es de 3000 caracteres.",
                                }
                              })}
                            ></textarea>              
                            {errors.instructions_message && <Alert>* {errors.instructions_message.message}</Alert>}       
                          </div>
                        </div>  

                        <div className="additionalOption">
                          <label>Cargar brochure: </label>
                          <label className="f-w-100 font-italic d-block" >Aquí podras cargar tus archivos con información de tu empresa si deseas, para que tu asistente tenga mas información de la empresa y productos/servicios, precios, ubicaciones, etc.(Debe ser PDF, TXT, WORD o POWERPOINT)</label>
                          <div className="su-control-group">
                            {/* {modeUpdate && isFile ? ( */}
                              <div className="content-img text-center my-2 d-flex align-items-center" style={{flexDirection:"column"}}>
                                <div className="align-items-center mb-1" style={{width: "60%"}}>
                                  {isFile && isFile.map((file, k) => {
                                    const ext = file.name.split('.').pop().toLowerCase();
                                    console.log('file------>', file);
                                    
                                  return(
                                    <div key={k} className="d-flex justify-content-between align-items-center">
                                      <label className="d-flex align-items-center mr-1">
                                        {ext === "docx" || ext === "doc" ? (
                                          <BsFillFileEarmarkWordFill className="mr-1" style={{fontSize: "18px", color: "#31acf6"}} />
                                        ) :  ext === "pdf" ? (
                                          <BsFillFileEarmarkPdfFill className="mr-1" style={{fontSize: "18px",color: "red",}}/>  
                                        ) : ext === "pptx" ? (
                                          <BsFillFileEarmarkPptFill className="mr-1 icon-file-earmark" style={{ fontSize: "18px",color: "#e54b2a"}} />
                                        ) : (
                                          <BsFillFileEarmarkFontFill className="mr-1" style={{ fontSize: "18px",color: "#5a5a5a"}} />
                                        )}
                                        {file.name.length >= 25 ? file.name.substr(0, 25) + "..." : file.name} 
                                      </label>
                                      <div className="d-flex align-items-center">
                                        <a className="d-flex align-items-center" href={file.url} alt="pdf" target="_blank" rel="noreferrer" title="Ver archivo">
                                          <FaEye 
                                            className="pointer ml-1 mr-1" 
                                            style={{
                                              color: "#ff9317", 
                                              fontSize: "19px"
                                            }} 
                                          />
                                        </a>
                                        <BsTrash3 
                                          className="pointer ml-3 mr-1" 
                                          style={{
                                            color: "red", 
                                            fontSize: "19px"
                                          }} 
                                          title="Eliminar archivo" 
                                          onClick={() => onDeleteFile(file.name, file.id)}
                                        />
                                      </div>
                                    </div>
                                  )})}
                                </div>
                                <a 
                                  className="d-flex align-items-center" 
                                  href="javascript:;" 
                                  alt="cargar..." 
                                  onClick={handleFileClick}
                                >
                                  Cargar <BsPlusSquareDotted className="text-primary ml-2" style={{ fontSize: "19px"}} />
                                </a>
                                <input
                                  ref={fileInputRef}
                                  type="file"
                                  name="image"
                                  id="file"
                                  style={{ position: "absolute", opacity: 0, zIndex: -1 }}
                                  className="form-control"
                                  accept="application/pdf, .txt, .pdf, .doc, .docx, .pptx"
                                  multiple={true} 
                                  // {...register("file")}
                                  onChange={(e) => onFile(e)}
                                />
                              </div>
                            {/* ) : (
                              <input
                                ref={fileInputRef}
                                type="file"
                                name="image"
                                id="file"
                                className="form-control"
                                accept="application/pdf, .txt, .pdf, .doc, .docx, .pptx"
                                multiple={true} 
                                {...register("file")}
                                onChange={(e) => onFile(e)}
                              />

                            )} */}
                          </div>
                        </div>

                        <div className="additionalOption">
                          <div className="d-flex align-items-center mb-2">
                            <label>Transferencias automáticas</label>
                            <div className="d-flex justify-content-center align-items-center ml-3">
                              <input
                                name="transfer"
                                id="transferCheckId"
                                type="checkbox"
                                className="cmn-toggle cmn-toggle-round-flat"
                                checked={transferCheck}
                                {...register("transferCheck")}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  setTransferCheck(isChecked);
                                  setValue("transferCheck", isChecked);
                                }}
                              />
                              <label className="m-0" htmlFor="transferCheckId"></label>
                            </div>
                          </div>

                          {transferCheck && (
                            <>
                              <label className="f-w-100 font-italic d-block" >Puedes elegir a que área o agente será transferido el usuario cuando termine o requiera hablar con un agente humano.</label>
                              <div className="su-control-group d-flex">
                                {radioOptions2 && radioOptions2.map((radio, k) => (
                                  <div key={k} className="d-flex pointer mr-1">
                                    <input
                                      style={{accentColor: '#5BC0DE'}}
                                      type="radio"
                                      value={radio.id}
                                      checked={typeArea === radio.id}
                                      className="pointer"
                                      {...register("transfer", { 
                                        required: {
                                          value: true,
                                          message:"El campo es requerido.",
                                        }
                                      })}
                                      onChange={(e) => {
                                        setTypeArea(e.target.value)
                                        setAgentArea("")
                                      }}
                                    />
                                    <label className="d-flex mb-0 ml-1 pointer d-flex align-items-center" style={{ padding: "5px 2px 5px" }}>{radio.name}</label>
                                  </div>
                                ))}
                              </div>

                              <div className="su-control-group d-flex">
                                <Select
                                  styles={isDark == 'true' ? customStylesDark : customStyles}
                                  className="form-control basic-multi-select"
                                  placeholder="Seleccione..."
                                  name="selectAreas"
                                  value={agentArea}
                                  onChange={(option) => {
                                    console.log('option:::', option);                                    
                                    setAgentArea(option); 
                                    setIsAgentArea(false)
                                  }}
                                  options={typeArea === "2" ? listAreas : listAdvisers}
                                  classNamePrefix="select"
                                  noOptionsMessage={() => "Sin opciones"}
                                  getOptionLabel={(option) => (
                                    <div className="option d-flex" style={{fontSize: "15px"}}>
                                      {option.label} 
                                    </div>
                                  )}
                                  getOptionValue={(option) => option.value}
                                />
                              </div>
                              {isAgentArea && <Alert>* Debes seleccionar una opción.</Alert>}

                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                <label>Mensaje: </label>
                                <label className="f-w-100 font-italic d-block" >Mensaje que recibira el usuario cuando se va a transferir. Puedes dejarlo así, si no sabes que escribir.</label>
                                <div className="su-control-group">
                                  <textarea
                                    className="form-control px-2"
                                    name="messageTransfer"
                                    id="messageTransfer"
                                    aria-readonly
                                    style={{ height: "120px" }}
                                    {...register("messageTransfer", {
                                      required: {
                                        value: true,
                                        message: "El mensaje de transferencia es requerido.",
                                      },
                                    })}
                                  ></textarea>
                                </div>
                              </div>
                              {errors.messageTransfer && <Alert>* {errors.messageTransfer.message}</Alert>} 
                            </>
                          )}
                        </div>  

                        <div className="additionalOption">
                          <div className="d-flex align-items-center mb-2">
                            <label>Agendamiento automático</label>
                            <div className="d-flex justify-content-center align-items-center ml-4">
                              <input
                                name="webhook"
                                id="addWebhook"
                                type="checkbox"
                                className="cmn-toggle cmn-toggle-round-flat"
                                checked={webhookCheck}
                                {...register("webhookCheck")}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  setWebhookCheck(isChecked);
                                  setValue("webhookCheck", isChecked);
                                }}
                              />
                              <label className="m-0" htmlFor="addWebhook"></label>
                            </div>
                          </div>

                          {webhookCheck && (
                            <>
                              <div>
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                  <label className="f-w-100 font-italic d-block" >Puedes agregar el webhook para realizar el agendamiento automático.</label>
                                  <div className="su-control-group">
                                    <input
                                      className="form-control px-2"
                                      name="webhookData"
                                      id="webhookData"
                                      aria-readonly
                                      {...register("webhookData", {
                                        required: {
                                          value: true,
                                          message: "El webhook es requerido.",
                                        },
                                      })}
                                    />
                                  </div>
                                </div>
                                {errors.webhookData && <Alert>* {errors.webhookData.message}</Alert>} 
                              </div>
                              <div>
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                  <label className="f-w-100 font-italic d-block" >Agrega el webhook para buscar eventos.</label>
                                  <div className="su-control-group">
                                    <input
                                      className="form-control px-2"
                                      name="webhookDataSearchEvents"
                                      id="webhookDataSearchEvents"
                                      aria-readonly
                                      {...register("webhookDataSearchEvents", {
                                        required: {
                                          value: true,
                                          message: "El webhook es requerido.",
                                        },
                                      })}
                                    />
                                  </div>
                                </div>
                                {errors.webhookDataSearchEvents && <Alert>* {errors.webhookDataSearchEvents.message}</Alert>} 
                              </div>
                              <div>
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                  <label className="f-w-100 font-italic d-block" >Agrega el webhook para cancelar un evento.</label>
                                  <div className="su-control-group">
                                    <input
                                      className="form-control px-2"
                                      name="webhookDataCancelEvent"
                                      id="webhookDataCancelEvent"
                                      aria-readonly
                                      {...register("webhookDataCancelEvent", {
                                        required: {
                                          value: true,
                                          message: "El webhook es requerido.",
                                        },
                                      })}
                                    />
                                  </div>
                                </div>
                                {errors.webhookDataCancelEvent && <Alert>* {errors.webhookDataCancelEvent.message}</Alert>} 
                              </div>
                              {
                                templates.length > 0 && (
                                  <>
                                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                      <label>Mensaje de Recordatorio:</label>
                                      <label className="f-w-100 font-italic d-block">⚠️Recuerda que la plantilla debe llevar los parámetros de Nombre, Fecha, Hora, Razón para que el envío sea éxitoso⚠️.</label>
                                      <div className="su-control-group">
                                        <Select
                                          styles={isDark == 'true' ? customStylesDark : customStyles}
                                          className="form-control select-template"
                                          placeholder="Selecciona una plantilla"
                                          value={templateSelected}
                                          onChange={handleChange}
                                          options={[...templates].reverse()}
                                          getOptionLabel={(option) => (
                                            <div className="option d-flex" style={{fontSize: "15px"}}>
                                              {`${option.name} - (${option.language})`}
                                            </div>
                                          )}
                                          getOptionValue={(option) => `${option.name} - ${option.language}`}
                                        />
                                      </div>
                                    </div>
                                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                      <label>Hora del Recordatorio:</label>
                                      <label className="f-w-100 font-italic d-block">Elige la hora en la que deseas que se envíe el mensaje.</label>
                                      <div className="su-control-group">
                                        <Select
                                          styles={isDark == 'true' ? customStylesDark : customStyles}
                                          className="form-control select-template"
                                          placeholder="Selecciona una hora"
                                          value={hourSelected}
                                          onChange={handleChangeHours}
                                          options={hoursSchduleOptions}
                                          getOptionLabel={(option) => (
                                            <div className="option d-flex" style={{fontSize: "15px"}}>
                                              {`${option.label}`}
                                            </div>
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                            </>
                          )}
                        </div> 
                      </div>
                    </div>
                  </div>
                ) : null}

              </div>

              
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalCreateAssistant((prevMessage) => { return false});
                }}
              >
                Cancelar
              </a>
              <input
                className="btn btn-lg btn-info"
                type="submit"
                value={`${modeUpdate ? "Actualizar" : "Aceptar"}`}
              />
            </div>
          </form>


        </Content>
      </Modal> 
    </>
  );
};

export default Assistants;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;