import React, { useEffect } from 'react'
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import 'dayjs/locale/es';

import List from "../components/content/List";
import Tags from "../components/content/Tags";
import Chat from "../components/content/Chat";
import Login from "../components/content/Login";
import Lists from "../components/content/Lists";
import Flows from "../components/content/Flows";
import Reset from "../components/content/Reset";
import Layout from "../components/layout/Layout";
import Person from "../components/content/Person";
import Orders from "../components/content/Orders";
import MyData from "../components/content/MyData";
import Persons from "../components/content/Persons";
import Catalog from "../components/content/Catalog";
import Support from "../components/content/Support";
import ChatBot from "../components/content/ChatBot";
import Campaign from "../components/content/Campaign";
import NotFound from "../components/plugins/NotFound";
import Template from "../components/content/Template";
import Dashboard from "../components/layout/Dashboard";
import Templates from "../components/content/Templates";
import Prospects from "../components/content/Prospects";
import Companies from "../components/content/Companies";
import Assistants from "../components/content/Assistants";
import UsersSystem from "../components/content/UsersSystem";
import OrderDetail from "../components/content/OrderDetail";
import AssistantAI from "../components/content/AssistantAI";
import LinesModule from '../components/content/LinesModule';
import SupportAdmin from "../components/content/SupportAdmin";
import ChatClosures from "../components/content/ChatClosures";
import SupportDetail from "../components/content/SupportDetail";
import AbsentMessage from "../components/content/AbsentMessage";
import BusinessHours from "../components/content/BusinessHours";
import PaymentMethods from "../components/content/PaymentMethods";
import ForgotPassword from "../components/content/ForgotPassword";
import CampaignReport from "../components/content/CampaignReport";
import PaymentSummary from "../components/content/PaymentSummary";
import CreatePassword from "../components/content/CreatePassword";
import BotsConstructor from "../components/content/BotsConstructor";
import HistoricalChats from "../components/content/HistoricalChats";
import TemplateWhatsapp from "../components/content/TemplateWhatsapp";
import AdditionalFields from "../components/content/AdditionalFields";
import ConsumptionPayments from "../components/content/ConsumptionPayments";
import ConsumptionByCompany from "../components/content/ConsumptionByCompany";
import TemplateWhatsappEdit from "../components/content/TemplateWhatsappEdit";

//Context y estados
import { useQuery } from '@tanstack/react-query';
import ListsState from "../context/Lists/ListsState";

const RoutesApp = () => {
    //#Estado para rol del usuario
    const {data: rolUser} = useQuery({
        queryKey: ['rolUser'],
        queryFn: () => {
            return localStorage.getItem('USER_ROLEDESCRIPTION');
        }
    }); 

    useEffect(() => {
      console.log('⭐⭐⭐⭐⭐⭐', rolUser);
    }, [rolUser]);
    

    return (
        <Router>
            <ListsState>
                <Routes history="hashHistory">
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword />} />
                    <Route exact path="/home/reset/:token" element={<Reset />} />
                    <Route exact path="/home/createPassword/:token" element={<CreatePassword />} />
                    <Route exact path="/admin" element={<Companies />} />
                    <Route exact path="/supportAdmin" element={<SupportAdmin />} />
                    <Route exact path="/consumptionByCompany" element={<ConsumptionByCompany />} />
                    <Route path="*" element={<NotFound />} />
                    <Route exact path="/home" element={<Layout />}>
                        {rolUser === "Administrador" ? (
                            <>
                                <Route index element={<Dashboard />} />
                                <Route exact path="/home/agents" element={<UsersSystem />} />
                                <Route exact path="/home/contacts" element={<Persons />} />
                                <Route exact path="/home/contact/:id" element={<Person />} />
                                <Route exact path="/home/additional-fields" element={<AdditionalFields />} />
                                <Route exact path="/home/lists" element={<Lists />} />
                                <Route exact path="/home/list/:id_param" element={<List />} />
                                <Route exact path="/home/tags" element={<Tags />} />
                                <Route exact path="/home/templates" element={<Templates />} />
                                <Route exact path="/home/flows" element={<Flows />} />
                                <Route exact path="/home/catalog" element={<Catalog />} />
                                <Route exact path="/home/orders" element={<Orders />} />
                                <Route exact path="/home/order/:id" element={<OrderDetail />} />
                                <Route exact path="/home/paymentMethods" element={<PaymentMethods />} />
                                <Route exact path="/home/template/:id" element={<Template />} />
                                <Route exact path="/home/campaign" element={<Campaign />} />
                                <Route exact path="/home/campaignReport/:id" element={<CampaignReport />} />
                                <Route exact path="/home/myData" element={<MyData />} />
                                <Route exact path="/home/businessHours" element={<BusinessHours />} />
                                <Route exact path="/home/absentMessage" element={<AbsentMessage />} />
                                <Route exact path="/home/chatClosures" element={<ChatClosures />} />
                                <Route exact path="/home/assistantAI" element={<AssistantAI />} />
                                <Route exact path="/home/assistants" element={<Assistants />} />
                                <Route exact path="/home/botsConstructor" element={<BotsConstructor />} />
                                <Route exact path="/home/consumptionPayments" element={<ConsumptionPayments />} />
                                <Route exact path="/home/templateWhatsapp" element={<TemplateWhatsapp />} />
                                <Route exact path="/home/templateWhatsappEdit/:id" element={<TemplateWhatsappEdit />} />
                                <Route exact path="/home/chat" element={<Chat />} />
                                <Route exact path="/home/support" element={<Support />} />
                                <Route exact path="/home/supportDetail/:id" element={<SupportDetail />}/>
                                <Route exact path="/home/chat-bot" element={<ChatBot />} />
                                <Route exact path="/home/paymentSummary" element={<PaymentSummary />} />
                                <Route exact path="/home/historicalChats" element={<HistoricalChats />} />
                                <Route exact path="/home/prospects" element={<Prospects />} />
                                <Route exact path="/home/lines" element={<LinesModule />} />
                                <Route path="*" element={<Navigate to='/home' replace />} />
                            </>
                        ) : (
                            <>
                                <Route index element={<Dashboard />} />
                                <Route exact path="/home/contacts" element={<Persons />} />
                                <Route exact path="/home/contact/:id" element={<Person />} />
                                <Route exact path="/home/chat" element={<Chat />} />
                                <Route path="*" element={<Navigate to='/home' replace />} />
                            </>
                        )}
                    </Route>
                </Routes>
            </ListsState>
        </Router>
    )
}

export default RoutesApp
